import { BrandAssetTypes } from "src/db/model/asset";

export const CARD_CONTENT_BY_TAG: CardContentByTag = {
  [BrandAssetTypes.productImages]: {
    order: 1,
    title: "Product Images",
    subtitle: (
      <>
        Photos or visuals of your products. Supported files:{" "}
        <b>JPG, PNG, GIF</b>
      </>
    ),
  },
  [BrandAssetTypes.brandingAssets]: {
    order: 2,
    title: "Branding Assets",
    subtitle: (
      <>
        Logos, fonts, and other brand visuals. Supported files:{" "}
        <b>JPG, PNG, SVG, TTF, OT</b>
      </>
    ),
  },
  [BrandAssetTypes.logoVariants]: {
    order: 3,
    title: "Logo Variants",
    subtitle: (
      <>
        Different versions of your logo. Supported files:{" "}
        <b>JPG, PNG, SVG, AI, EPS</b>
      </>
    ),
  },
  [BrandAssetTypes.fonts]: {
    order: 4,
    title: "Fonts",
    subtitle: (
      <>
        Typography files used in your branding. Supported files:{" "}
        <b>TTF, OTF, WOFF, WOFF2</b>
      </>
    ),
  },
  [BrandAssetTypes.brandingColors]: {
    order: 5,
    title: "Brand Colors",
    subtitle: (
      <>
        Colors and codes for your brand identity. Supported files:{" "}
        <b>ASE, ACO, JSON, CSV, XLSX.</b>
      </>
    ),
  },
  [BrandAssetTypes.marketingData]: {
    order: 6,
    title: "Market Data",
    subtitle: (
      <>
        Campaign materials and analytics. Supported files:{" "}
        <b>PDF, PPTX, JPG, PNG, CSV</b>
      </>
    ),
  },
  [BrandAssetTypes.salesData]: {
    order: 7,
    title: "Sales Data",
    subtitle: (
      <>
        Reports, metrics, and performance data. Supported files:{" "}
        <b>CSV, XLSX, PDF</b>
      </>
    ),
  },
  [BrandAssetTypes.other]: {
    order: 8,
    title: "Other",
    subtitle: (
      <>
        Any additional files or resources. Supported files:{" "}
        <b>All common files</b>
      </>
    ),
  },
};

export const ASSET_ACCEPT: AssetAccept = {
  [BrandAssetTypes.productImages]: ".jpg, .jpeg, .png, .gif",
  [BrandAssetTypes.logoVariants]: ".jpg, .jpeg, .png, .svg, .ai, .eps",
  [BrandAssetTypes.fonts]: ".ttf, .otf, .woff, .woff2",
  [BrandAssetTypes.brandingColors]: ".ase, .aco, .json, .csv, .xlsx",
  [BrandAssetTypes.marketingData]:
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf",
  [BrandAssetTypes.salesData]:
    ".csv, .xlsx, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf",
  [BrandAssetTypes.other]: "*/*",
};

export const ASSET_TAGS = {
  [BrandAssetTypes.productImages]: { label: "Product Images" },
  [BrandAssetTypes.brandingAssets]: {
    label: "Branding Assets",
    children: {
      [BrandAssetTypes.logoVariants]: { label: "Logo Variants" },
      [BrandAssetTypes.fonts]: { label: "Fonts" },
      [BrandAssetTypes.brandingColors]: { label: "Branding Colors" },
    },
  },
  [BrandAssetTypes.marketingData]: { label: "Marketing Data" },
  [BrandAssetTypes.salesData]: { label: "Sales Data" },
  [BrandAssetTypes.other]: { label: "Other" },
};

export const SLOT_CONTENT_BY_TAG: CardContentByTag = {
  [BrandAssetTypes.logoVariants]: {
    title: "Logo Variants",
    order: 1,
  },
  [BrandAssetTypes.fonts]: {
    title: "Fonts",
    order: 2,
  },
  [BrandAssetTypes.brandingColors]: {
    title: "Brand Colors",
    order: 3,
  },
};
