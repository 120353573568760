import { CopilotKit, useCoAgent } from "@copilotkit/react-core";
import { CopilotChat, useCopilotChatSuggestions } from "@copilotkit/react-ui";
import { ModelSelectorProvider, useModelSelectorContext } from "src/lib/model-selector-provider";
import { AgentState } from "src/lib/types";
import { Path } from "src/nav";
import { HeaderFooterPage } from "src/pages/wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "src/pages/wrappers/RequireBusiness";
import { ResearchCanvas } from "./ResearchCanvas";

export const MultiAgentAppPage = () => <ModelSelectorProvider><Main /></ModelSelectorProvider>

const Main = () => {
  const { agent } = useModelSelectorContext();
  return (
    <CopilotKit runtimeUrl="https://copilot-multi-agent-client-651903022604.us-west2.run.app/api/copilotkit" agent={agent}>
      <HeaderFooterPage pageTitle="Multi Agent" backTo={Path.home}>
          <RequireBusinessWithSignup>
          <MultiAgentAppPageContent />
          </RequireBusinessWithSignup>
      </HeaderFooterPage>
    </CopilotKit>
  );
}

const MultiAgentAppPageContent = () => {
    const { model, agent } = useModelSelectorContext();
  const { state, setState } = useCoAgent<AgentState>({
    name: agent,
    initialState: {
      model,
      research_question: "",
      resources: [],
      report: "",
      logs: [],
    },
  });

  useCopilotChatSuggestions({
    instructions: "Market data research",
  });
  
  return (
    <>
      <div
        className="flex flex-1 border"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <div className="flex-1 overflow-hidden">
          <ResearchCanvas />
        </div>
        <div
          className="w-[500px] h-full flex-shrink-0"
          style={
            {
              "--copilot-kit-background-color": "#E0E9FD",
              "--copilot-kit-secondary-color": "#6766FC",
              "--copilot-kit-secondary-contrast-color": "#FFFFFF",
              "--copilot-kit-primary-color": "#FFFFFF",
              "--copilot-kit-contrast-color": "#000000",
            } as any
          }
        >
          <CopilotChat
            className="h-full"
            onSubmitMessage={async (message) => {
              // clear the logs before starting the new research
              setState({ ...state, logs: [] });
              await new Promise((resolve) => setTimeout(resolve, 30));
            }}
            labels={{
              initial: "Hi! How can I assist you with your research today?",
            }}
          />
        </div>
      </div>
    </>
  );
};
