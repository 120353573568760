import React, { useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  useDisclosure,
  Image,
  Input,
  Box,
} from "@chakra-ui/react";

import { useApi } from "src/api";
import { useBusinessId } from "src/db";
import { CaretLeft, FilePlus, PlusCircle, TrashWhite } from "src/images";
import { BrandAssetTypes } from "src/db/model/asset";
import { ASSET_TAGS } from "../brandAssetsCard/constants";

const MODAL_STEPS = {
  upload: "upload",
  tags: "tags",
};

interface AddBrandAssetButtonProp extends ButtonProps {
  accept?: string;
  selectedTag?: string;
}

interface TagProps {
  key: string;
  label: string;
  active: boolean;
  handleClick: (key: string) => void;
}

const Tag = ({ key, label, active, handleClick }: TagProps) => (
  <Button
    variant="outline"
    size="sm"
    bg={active ? "primary.dark" : "gray.darkLightest"}
    color="white"
    border="none"
    onClick={() => handleClick(key)}
  >
    {label}
  </Button>
);

export function AddBrandAssetButton({
  children,
  accept,
  selectedTag,
  ...buttonProps
}: AddBrandAssetButtonProp) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList>();

  const selectedTagsDefault = selectedTag ? [selectedTag] : [];

  const [modalStep, setModalStep] = useState(MODAL_STEPS.upload);
  const [selectedTags, setSelectedTags] =
    useState<string[]>(selectedTagsDefault);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    if (isSubmitting || !files) {
      return;
    }
    setIsSubmitting(true);
    const data = new FormData();
    data.append("businessId", businessId);
    Array.from(files).forEach((file) => {
      data.append("files", file);
    });
    if (Boolean(selectedTags.length)) {
      selectedTags.forEach((tag) => {
        data.append("tags", tag);
      });
    }
    api
      .addBrandAsset(data, surfaceKnownErrors)
      .then(() => {
        handleModalClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleModalClose = () => {
    setFiles(undefined);
    if (!selectedTag) {
      setSelectedTags([]);
    }
    onClose();
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleModalBack = () => {
    if (modalStep === MODAL_STEPS.upload) {
      handleModalClose();
    } else if (modalStep === MODAL_STEPS.tags) {
      setModalStep(MODAL_STEPS.upload);
    }
  };

  const handleModalNext = () => {
    if (modalStep === MODAL_STEPS.upload) {
      setModalStep(MODAL_STEPS.tags);
    } else if (modalStep === MODAL_STEPS.tags) {
      handleSubmit();
    }
  };

  const handleTagClick = (key: string) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(key)) {
        return prevTags.filter((tag) => tag !== key);
      } else {
        return [...prevTags, key];
      }
    });
  };

  return (
    <>
      {children ? (
        <Flex w="full">
          {React.cloneElement(children as React.ReactElement, {
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              handleButtonClick();
            },
          })}
        </Flex>
      ) : (
        <Flex
          borderRadius="8px"
          flexDirection="column"
          borderColor="primary.dark"
          borderWidth="1px"
          borderStyle="dashed"
          gap="16px"
          p="24px 12px"
          w="full"
          alignItems="center"
          bg="linear-gradient(0deg, rgba(21, 142, 153, 0.05) 0%, rgba(21, 142, 153, 0.05) 100%)"
        >
          <Text align="center" color="gray.medium" fontSize="13px">
            Click to add multiple files <br /> Supported files:{" "}
            <b>JPG, PNG, SVG, AI, EPS</b>
          </Text>
          <Button
            {...buttonProps}
            borderColor="primary.dark"
            borderWidth="1px"
            borderStyle="solid"
            w="175px"
            display="flex"
            gap="8px"
            borderRadius="37px"
            bg="white"
            onClick={handleButtonClick}
          >
            <Image src={PlusCircle} color={"black"} boxSize="24px" />
            <Text color="black"> Quick Upload</Text>
          </Button>
        </Flex>
      )}
      <Input
        ref={fileInputRef}
        type="file"
        accept={accept}
        display="none"
        onChange={(e) => {
          const newFiles = e.target.files;
          if (newFiles) {
            setFiles((prevFiles) => {
              if (!prevFiles) return newFiles;
              const fileArray = Array.from(prevFiles);
              const newFilesArray = Array.from(newFiles);
              const dataTransfer = new DataTransfer();
              fileArray.forEach((file) => dataTransfer.items.add(file));
              newFilesArray.forEach((file) => dataTransfer.items.add(file));
              return dataTransfer.files;
            });
            onOpen();
          }
        }}
        multiple
      />
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        initialFocusRef={inputRef}
        size="full"
      >
        <ModalOverlay />
        <ModalContent
          mt="100px"
          borderRadius="16px 16px 0 0"
          maxH="75%"
          maxWidth={800}
        >
          <ModalHeader display="flex">
            <Image
              src={CaretLeft}
              alt="back"
              position="absolute"
              left="16px"
              onClick={handleModalBack}
            />
            <Center marginX="auto">
              <Text textStyle="bodyLarge" color="gray.dark" fontWeight="bold">
                Upload
              </Text>
            </Center>
          </ModalHeader>
          <ModalBody>
            {modalStep === MODAL_STEPS.upload && (
              <Flex
                flexDirection="column"
                gap="16px"
                my="8px"
                borderRadius="8px"
                border="1px solid #E4E8D4"
                p="16px"
                height="450px"
              >
                <Text
                  color="primary.veryDark"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Selected documents
                </Text>
                <Text color="gray.medium" fontSize="13px">
                  {files?.length} files (
                  {Math.round(
                    Array.from(files || []).reduce(
                      (acc, file) => acc + file.size,
                      0
                    ) / 1024
                  )}{" "}
                  KB)
                </Text>
                <Flex gap="8px" wrap="wrap">
                  {files &&
                    Array.from(files).map((file: File, i: number) => (
                      <Box
                        w="98px"
                        height="98px"
                        borderRadius="12px"
                        position="relative"
                        key={`${file.name}-${i}`}
                      >
                        <Image
                          src={URL.createObjectURL(file)}
                          fallback={
                            <Center
                              w="full"
                              h="full"
                              borderRadius="12px"
                              bg="gray.200"
                              color="gray.600"
                              fontSize="12px"
                              fontWeight="bold"
                              p="2"
                            >
                              <Text isTruncated>{file.name}</Text>
                            </Center>
                          }
                          alt={file.name}
                          w="full"
                          h="full"
                          objectFit="cover"
                          borderRadius="12px"
                        />
                        <Box
                          position="absolute"
                          top="5px"
                          right="5px"
                          bg="#00000080"
                          borderRadius="50%"
                          w="24px"
                          h="24px"
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            setFiles((prevFiles) => {
                              if (!prevFiles) return prevFiles;
                              const fileArray = Array.from(prevFiles);
                              fileArray.splice(i, 1);
                              const dataTransfer = new DataTransfer();
                              fileArray.forEach((file) =>
                                dataTransfer.items.add(file)
                              );
                              return dataTransfer.files;
                            });
                          }}
                        >
                          <Image
                            src={TrashWhite}
                            alt="delete image"
                            height="18px"
                          />
                        </Box>
                      </Box>
                    ))}
                </Flex>
                <Flex w="100%">
                  <Button
                    borderColor="gray.light"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderRadius="7px"
                    w="100%"
                    display="flex"
                    gap="8px"
                    onClick={handleButtonClick}
                  >
                    <Image src={FilePlus} />
                    <Text fontSize="13px" color="black">
                      Add {Boolean(files?.length) && "More"}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            )}

            {modalStep === MODAL_STEPS.tags && (
              <Flex
                flexDirection="column"
                gap="16px"
                my="8px"
                borderRadius="8px"
                border="1px solid #E4E8D4"
                p="16px"
                height="450px"
              >
                <Text
                  color="primary.veryDark"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Document type
                </Text>
                <Text color="gray.medium" fontSize="13px">
                  Choose the tags relevant to these files
                </Text>
                <Flex gap="8px" wrap="wrap">
                  {Object.entries(ASSET_TAGS).map(([key, value]) => {
                    if ("children" in value) {
                      return Object.entries(value.children).map(
                        ([childKey, childValue]) => (
                          <Tag
                            key={childKey}
                            label={childValue.label}
                            active={selectedTags.includes(childKey)}
                            handleClick={() => handleTagClick(childKey)}
                          />
                        )
                      );
                    } else {
                      return (
                        <Tag
                          key={key}
                          label={value.label}
                          active={selectedTags.includes(key)}
                          handleClick={() => handleTagClick(key)}
                        />
                      );
                    }
                  })}
                </Flex>
              </Flex>
            )}
            <Flex height="200px" alignItems="center" justifyContent="center">
              <Button
                colorScheme="primary"
                variant={
                  modalStep === MODAL_STEPS.tags &&
                  !Boolean(selectedTags.length)
                    ? "outline"
                    : "fill"
                }
                width="100%"
                borderRadius="7px"
                isLoading={isSubmitting}
                my="auto"
                disabled={!files}
                onClick={handleModalNext}
              >
                {modalStep === MODAL_STEPS.upload
                  ? "Next"
                  : !Boolean(selectedTags.length)
                  ? "Skip and Categorize Later"
                  : "Done"}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
