import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { BoxProps } from '@chakra-ui/react';

import { LogoAssetDocument } from 'src/db';
import { Logo } from 'src/components/assets';

interface LogoPreviewProps extends BoxProps {
  logo: LogoAssetDocument,
}
export function LogoPreview({logo, ...boxProps}: LogoPreviewProps) {
  return (<Zoom>
    <Logo
      logo={logo}
      shadow='card'
      borderRadius='md'
      isDynamic={logo.origin !== 'USER'}
      maxWidth={{ lg: "400px" }}
    />
  </Zoom>);
}
