import { Box, Flex, Divider, Progress, Center } from "@chakra-ui/react";

import { AssetTypes, useAssetContext } from "src/db";
import { Path } from "src/nav";

import { HeaderFooterPage } from "../../wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "../../wrappers/RequireBusiness";

import { AddBrandAssetButton } from "src/components/assets/addAsset/AddBrandAsset";
import BrandAssetCard from "src/components/assets/brandAssetsCard/BrandAssetCard";
import { BrandAssetTypes } from "src/db/model/asset";

const SIDE_PADDING = 4;

export function BrandingAssetsPage() {
  return (
    <HeaderFooterPage pageTitle="Branding Assets" backTo={Path.brandAssets}>
      <RequireBusinessWithSignup>
        <BrandingAssetsPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function BrandingAssetsPageContent() {
  const {
    collection: brandAssets,
    isLoading: logoAssetsAreLoading,
    error: collectionError,
  } = useAssetContext(AssetTypes.brandAsset);

  const brandingAssets = (brandAssets as Record<string, any>)[BrandAssetTypes.brandingAssets];

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  }

  if (logoAssetsAreLoading) {
    return (
      <>
        <Box py={8} px={SIDE_PADDING}>
          <Progress size="lg" isIndeterminate={true} />
        </Box>
      </>
    );
  }

  return (
    <Box bg="gray.offwhite">
      <Center my="12px">
        <AddBrandAssetButton accept=".jpg, .jpeg, .png, .svg, .ai, .eps" />
      </Center>
      <Flex align="center" direction="column" justify="start" mb={4}>
        <Divider borderColor="secondary.light" mb={8} />
        {Object.keys(brandingAssets?.children).map((tag: any) => {
          const data: any = brandingAssets.children[tag];
          return <BrandAssetCard key={tag} tag={tag} data={data} />;
        })}
      </Flex>
    </Box>
  );
}
