import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaList, FaThLarge } from "react-icons/fa";
import { BrandAssetDocument } from "src/db";
import { Dots, DotsThreeCircle } from "src/images";
import { AssetActions } from "src/components/assets/brandAssetsCard/BrandAssetCard";
import { MdCheck } from "react-icons/md";
import { IoClose } from "react-icons/io5";

function FileViewer({ files }: { files: BrandAssetDocument[] }) {
  const [isListView, setIsListView] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<BrandAssetDocument[]>(
    []
  );
  const [showSelect, setShowSelect] = useState(false);

  const handleSelectAssets = (asset: BrandAssetDocument) => {
    if (!showSelect && !selectedAssets.length) {
      setShowSelect(true);
    }

    setSelectedAssets((prevSelectedAssets) => {
      if (
        prevSelectedAssets.some(
          (selectedAsset) => selectedAsset.id === asset.id
        )
      ) {
        return prevSelectedAssets.filter(
          (selectedAsset) => selectedAsset.id !== asset.id
        );
      } else {
        return [...prevSelectedAssets, asset];
      }
    });
  };

  const handleSelectClose = () => {
    setShowSelect(false);
    setSelectedAssets([]);
  };

  return (
    <VStack w="full">
      {/* Toggle View Button */}
      <ButtonGroup isAttached borderRadius="8px" borderWidth={1} p={1} w="full">
        <Button
          leftIcon={<FaThLarge />}
          onClick={() => setIsListView(false)}
          colorScheme={!isListView ? "primary.dark" : "white"}
          bg={!isListView ? "primary.dark" : "white"}
          w="50%"
          borderRadius="4px"
          color={!isListView ? "white" : "gray.medium"}
        >
          Thumbnails
        </Button>
        <Button
          leftIcon={<FaList />}
          colorScheme={isListView ? "primary.dark" : "white"}
          onClick={() => setIsListView(true)}
          bg={isListView ? "primary.dark" : "white"}
          w="50%"
          borderRadius="4px"
          color={isListView ? "white" : "gray.medium"}
        >
          List
        </Button>
      </ButtonGroup>

      {/* File Display */}
      {isListView ? (
        <VStack w="full" align="stretch">
          {files.map((file: BrandAssetDocument) => {
            const isAssetSelected = selectedAssets.some(
              (asset) => asset.id === file.id
            );
            return (
              <HStack
                key={file.id}
                py={3}
                w="full"
                display="flex"
                justifyContent="space-between"
                bg={
                  isAssetSelected
                    ? "linear-gradient(0deg, rgba(21, 142, 153, 0.10) 0%, rgba(21, 142, 153, 0.10) 100%)"
                    : "transparent"
                }
              >
                <Flex alignItems="center" gap="8px" borderRadius="8px" p="2">
                  {showSelect ? (
                    <Checkbox
                      checked={isAssetSelected}
                      defaultChecked={isAssetSelected}
                      onChange={() => handleSelectAssets(file)}
                    />
                  ) : null}
                  <Image
                    src={file.value.brand.fileInfo.fileUrl}
                    boxSize="20px"
                    borderRadius="md"
                    objectFit="cover"
                    fallback={
                      <Box w="20px">
                        <Center
                          w="20px"
                          h="20px"
                          borderRadius="12px"
                          bg="gray.lightest"
                          fontWeight="bold"
                        />
                      </Box>
                    }
                  />
                  <Box maxW="300px">
                    <Text isTruncated>{file.value.brand.name}</Text>
                  </Box>
                </Flex>
                <Flex mb="2">
                  <AssetActions
                    position="relative"
                    icon={Dots}
                    background="transparent"
                    assets={file}
                    handleSelectAssets={handleSelectAssets}
                    handleClearSelectAssets={handleSelectClose}
                  />
                </Flex>
              </HStack>
            );
          })}
        </VStack>
      ) : (
        <SimpleGrid columns={2} spacing={4} w="full">
          {files.map((file: BrandAssetDocument) => {
            const isAssetSelected = selectedAssets.some(
              (asset) => asset.id === file.id
            );
            return (
              <Box key={file.id} py={3}>
                <Box position="relative">
                  {showSelect ? (
                    <Box
                      position="absolute"
                      top="8px"
                      left="8px"
                      w="24px"
                      h="24px"
                      bg={isAssetSelected ? "primary.dark" : "transparent"}
                      borderRadius="12px"
                      borderWidth={3}
                      borderColor={isAssetSelected ? "white" : "gray.light"}
                      cursor="pointer"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => handleSelectAssets(file)}
                      zIndex={1}
                    >
                      {isAssetSelected ? (
                        <Icon as={MdCheck} color="white" boxSize="18px" />
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : null}
                  <Image
                    src={file.value.brand.fileInfo.fileUrl}
                    boxSize="100%"
                    minHeight="150px"
                    borderRadius="12px"
                    objectFit="cover"
                    fallback={
                      <Box w="130px">
                        <Center
                          w="130px"
                          h="160px"
                          borderRadius="12px"
                          bg="gray.lightest"
                          fontWeight="bold"
                          p="2"
                          mb="2"
                        />
                      </Box>
                    }
                  />
                  <AssetActions
                    assets={file}
                    handleSelectAssets={handleSelectAssets}
                    handleClearSelectAssets={handleSelectClose}
                  />
                </Box>
                <Box maxW="100px">
                  <Text fontSize="13px" color="black" isTruncated>
                    {file.value.brand.name}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      )}

      {showSelect && (
        <Flex
          w="100%"
          position="fixed"
          top={0}
          left={0}
          height="80px"
          bg="white"
          borderBottomRadius={16}
          boxShadow="0px 0px 29px 0px rgba(0, 0, 0, 0.10)"
          padding="24px 16px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Icon
              as={IoClose}
              cursor="pointer"
              onClick={handleSelectClose}
              boxSize="20px"
              color="#193638"
            />
            <Text color="gray.dark" fontSize="14px">
              {selectedAssets.length} items
            </Text>
          </Flex>
          {selectedAssets.length > 0 && (
            <Flex alignItems="center">
              <AssetActions
                position="relative"
                icon={DotsThreeCircle}
                iconHeight="24px"
                background="transparent"
                assets={selectedAssets}
                handleSelectAssets={handleSelectAssets}
                handleClearSelectAssets={handleSelectClose}
              />
            </Flex>
          )}
        </Flex>
      )}
    </VStack>
  );
}

export default FileViewer;
