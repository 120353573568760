import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Text,
  VStack,
  Image,
  Grid,
  CardFooter,
  Button,
  ResponsiveValue,
  Flex,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { BrandAssetDocument } from "src/db";
import { DotsThreeVerticalOutline } from "src/images";
import { useCallback, useState } from "react";
import { Path, useNavigateWithParams } from "src/nav";
import {
  ASSET_ACCEPT,
  CARD_CONTENT_BY_TAG,
  SLOT_CONTENT_BY_TAG,
} from "./constants";
import { BrandAssetTypes } from "src/db/model/asset";
import { Carousel } from "src/components/Carousel";
import { AddBrandAssetButton } from "../addAsset/AddBrandAsset";
import AssetDropdownActions from "./AssetDropdownActions";
import { useRemoveBrandAsset, useUpdateBrandAsset } from "../actions/hooks";
import ConfirmModal from "src/components/ConfirmModal";
import EditTagsModal from "./EditTagsModal";

interface IBrandAssetCardProps {
  data: any;
  tag: any;
}

const BrandAssetCard = ({ data, tag }: IBrandAssetCardProps) => {
  const navigate = useNavigateWithParams();
  const goToBrandAssetPage = useCallback(
    (tag: keyof typeof Path) => {
      navigate({ to: Path[tag] });
    },
    [navigate]
  );

  const cardContentByTag = CARD_CONTENT_BY_TAG[tag as BrandAssetTypes];

  return (
    <Card key={tag} mb={12} p={6} w="full" order={cardContentByTag?.order}>
      <CardHeader
        mb={2}
        onClick={() => goToBrandAssetPage(tag)}
        cursor="pointer"
      >
        <Text as="header" textStyle="titleForSection" fontSize="16px">
          {cardContentByTag?.title}
        </Text>
        <Text textStyle="body" mb={2} fontSize="13px" color="gray.medium">
          {cardContentByTag?.subtitle}
        </Text>
      </CardHeader>
      <CardBody mb={2}>
        <VStack align="left">
          {!data.children && (
            <Carousel itemWidth={150} itemSpacing={16} mb={2} height={200}>
              {data.map((asset: BrandAssetDocument, idx: number) => {
                return (
                  <Box position="relative" key={idx}>
                    <Image
                      key={idx}
                      src={asset.value.brand.fileInfo.fileUrl}
                      height="180px"
                      objectFit="cover"
                      borderRadius="12px"
                      fallback={
                        <Box w="130px">
                          <Center
                            w="150px"
                            h="150px"
                            borderRadius="12px"
                            bg="gray.lightest"
                            fontWeight="bold"
                            p="2"
                            mb="2"
                          />
                          <Text
                            color="gray.dark"
                            fontSize="13px"
                            fontWeight="bold"
                            isTruncated
                          >
                            {asset.value.brand.name}
                          </Text>
                        </Box>
                      }
                    />
                    <AssetActions assets={asset} />
                  </Box>
                );
              })}
            </Carousel>
          )}
          {data.children && (
            <Carousel itemWidth={200} itemSpacing={16} mb={2}>
              {Object.keys(data.children)
                .sort(
                  (a, b) =>
                    (SLOT_CONTENT_BY_TAG[a as BrandAssetTypes]?.order ?? 0) -
                    (SLOT_CONTENT_BY_TAG[b as BrandAssetTypes]?.order ?? 0)
                )
                .map((tag) => {
                  const assets = data.children[tag];
                  const slotContent =
                    SLOT_CONTENT_BY_TAG[tag as BrandAssetTypes];
                  return (
                    <AssetsSlots
                      assets={assets}
                      title={slotContent?.title}
                      key={tag}
                    />
                  );
                })}
            </Carousel>
          )}
        </VStack>
      </CardBody>
      <CardFooter>
        <AddBrandAssetButton
          accept={ASSET_ACCEPT[tag as BrandAssetTypes]}
          selectedTag={data.children ? undefined : tag}
        >
          <Button
            colorScheme="primary"
            variant="fill"
            w="full"
            mx="auto"
            borderRadius={7}
          >
            Add
          </Button>
        </AddBrandAssetButton>
      </CardFooter>
    </Card>
  );
};

const AssetsSlots = ({
  assets = [],
  title,
}: {
  assets: BrandAssetDocument[] | null[];
  title?: string;
}) => {
  const assetsSlots = [
    ...assets,
    ...Array(Math.max(0, 4 - assets.length)).fill(null),
  ];
  const navigate = useNavigateWithParams();

  const navigateToBrandingAssets = () => {
    navigate({ to: Path.brandingAssets });
  };

  return (
    <>
      <Box
        borderWidth="1px"
        borderRadius="21px"
        overflow="hidden"
        maxW="sm"
        cursor="pointer"
        onClick={navigateToBrandingAssets}
      >
        <Grid templateColumns="repeat(2, 1fr)" gap={1}>
          {assetsSlots.map((asset, index) => (
            <Box
              key={index}
              bg="gray.100"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {asset ? (
                <Image
                  src={asset.value.brand.fileInfo.fileUrl}
                  alt={`image-${index}`}
                  objectFit="cover"
                  boxSize="100%"
                />
              ) : null}
            </Box>
          ))}
        </Grid>
      </Box>
      <Text mt={2} fontWeight="bold" textAlign="center">
        {title}
      </Text>
    </>
  );
};

export const AssetActions = ({
  position = "absolute",
  background = "#00000080",
  icon = DotsThreeVerticalOutline,
  iconHeight = "18px",
  assets,
  handleSelectAssets,
  handleClearSelectAssets,
}: {
  position?: ResponsiveValue<any>;
  icon?: string;
  iconHeight?: string;
  background?: string;
  assets: BrandAssetDocument | BrandAssetDocument[];
  handleSelectAssets?: (asset: BrandAssetDocument) => void;
  handleClearSelectAssets?: () => void;
}) => {
  const {
    isOpen: isRenameOpen,
    onOpen: onRenameOpen,
    onClose: onRenameClose,
  } = useDisclosure();

  const {
    isOpen: isRemoveFileOpen,
    onOpen: onRemoveFileOpen,
    onClose: onRemoveFileClose,
  } = useDisclosure();

  const {
    isOpen: isEditTagsOpen,
    onOpen: onEditTagsOpen,
    onClose: onEditTagsClose,
  } = useDisclosure();

  const [isRemovePending, setIsRemovePending] = useState<boolean>(false);

  const removeBrandAsset = useRemoveBrandAsset();

  const handleRemoveAsset = useCallback(() => {
    if (isRemovePending) {
      return;
    }
    removeBrandAsset(assets, setIsRemovePending).then(() => {
      onRemoveFileClose();
      handleClearSelectAssets && handleClearSelectAssets();
    });
  }, [isRemovePending, removeBrandAsset]);

  return (
    <>
      <Flex
        justifyContent="flex-end"
        w="full"
        position={position}
        top="5px"
        right="5px"
      >
        <AssetDropdownActions
          assets={assets}
          handleRenameFile={onRenameOpen}
          handleRemoveFile={onRemoveFileOpen}
          handleEditTags={onEditTagsOpen}
          handleSelectAssets={handleSelectAssets}
        >
          <Box
            bg={background}
            borderRadius="50%"
            w="24px"
            h="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            zIndex={1}
          >
            <Image src={icon} height={iconHeight} />
          </Box>
        </AssetDropdownActions>
      </Flex>
      {!Array.isArray(assets) && (
        <RenameModal
          asset={assets}
          isOpen={isRenameOpen}
          handleClose={onRenameClose}
        />
      )}
      <ConfirmModal
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onConfirm={handleRemoveAsset}
        message={`Are you sure you want to delete ${
          Array.isArray(assets) ? "these assets?" : "this asset?"
        }`}
        size="sm"
        loadingConfirm={isRemovePending}
      />
      {isEditTagsOpen && (
        <EditTagsModal assets={assets} onClose={onEditTagsClose} />
      )}
    </>
  );
};

const RenameModal = ({
  isOpen,
  asset,
  handleClose,
}: {
  isOpen: boolean;
  asset: BrandAssetDocument;
  handleClose: () => void;
}) => {
  const [newFileName, setNewFileName] = useState(asset.value.brand.name);
  const [isRenamePending, setIsRenamePending] = useState<boolean>(false);

  const updateBrandAsset = useUpdateBrandAsset();

  const handleUpdateBrandAsset = useCallback(() => {
    if (isRenamePending) {
      return;
    }
    updateBrandAsset(asset, { fileName: newFileName }, setIsRenamePending).then(
      () => {
        handleClose();
      }
    );
  }, [isRenamePending, updateBrandAsset]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFileName(event.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="sm"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter new file name"
            defaultValue={newFileName}
            onChange={handleInputChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleUpdateBrandAsset}
            isLoading={isRenamePending}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BrandAssetCard;
