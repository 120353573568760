import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Firestore,
  collection,
  doc,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import { useUser } from "src/auth";
import {
  ProfileDocument,
  profileConverter,
  useProfileId,
  ProfileBusinessDocument,
  profileBusinessConverter,
  BusinessDocument,
  businessConverter,
  BusinessCreditsDocument,
  businessCreditsConverter,
} from "src/db";
import { SearchParam, useSearchParam, useSearchParamState } from "src/nav";
import { logger, LogSource } from "src/util/logger";

import { ActionsProvider } from "./ActionsProvider";
import { AssetsProvider } from "./AssetsProvider";
import { FirestoreContext, ProfileContext, BusinessContext } from "./contexts";
import { ReportsProvider } from "./ReportsProvider";
import { MembersProvider } from "./MembersProvider";
import { NotificationsProvider } from "./NotificationsProvider";
import { backendConfig } from "src/firebaseAndBackendConfig";
import { BusinessWithDetails } from "./model/profileBusiness";

declare global {
  interface Window {
    Appcues: any;
  }
}

const missingProfileContext = {
  profileId: null,
  profile: null,
  isLoading: false,
  credits: null,
};

const loadingProfileContext = {
  profileId: null,
  profile: null,
  isLoading: true, // If auth is null, treat profile as still loading.
  credits: null,
};

const missingBusinessContext = {
  businessId: null,
  business: null,
  isLoading: false,
  credits: null,
  setSelectedBusinessId: () => {},
  setBusinessesDetails: () => {},
};

const loadingBusinessContext = {
  businessId: null,
  business: null,
  isLoading: true, // If auth is null, treat profile as still loading.
  credits: null,
  setSelectedBusinessId: () => {},
  setBusinessesDetails: () => {},
};

interface FirestoreProviderProps extends PropsWithChildren {
  fs: Firestore;
}
export function FirestoreProvider({
  fs,
  children,
}: PropsWithChildren<FirestoreProviderProps>) {
  return (
    <FirestoreContext.Provider value={fs}>
      <ProfileAndBusinessProvider>
        <AssetsProvider>
          <ActionsProvider>
            <ReportsProvider>
              <MembersProvider>
                <NotificationsProvider>{children}</NotificationsProvider>
              </MembersProvider>
            </ReportsProvider>
          </ActionsProvider>
        </AssetsProvider>
      </ProfileAndBusinessProvider>
    </FirestoreContext.Provider>
  );
}

function ProfileAndBusinessProvider({ children }: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const currentUser = useUser(true);
  const userId = currentUser?.uid || "-1";
  const claimBusinessId = useSearchParam(SearchParam.claimBusiness);

  const profilesRef = query<ProfileDocument>(
    collection(fs, "profiles").withConverter<ProfileDocument>(profileConverter),
    where("userId", "==", userId)
  );
  const [profiles, isLoading, error] = useCollectionData(profilesRef);
  const profile = (!!profiles && profiles[0]) || undefined;

  if (!!error) {
    logger.error(
      LogSource.FIRESTORE,
      "Error retreiving Profile Provider:",
      error
    );
    return (
      <ProfileContext.Provider value={missingProfileContext}>
        <BusinessContext.Provider value={missingBusinessContext}>
          {children}
        </BusinessContext.Provider>
      </ProfileContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <ProfileContext.Provider value={loadingProfileContext}>
        <BusinessContext.Provider value={missingBusinessContext}>
          {children}
        </BusinessContext.Provider>
      </ProfileContext.Provider>
    );
  }

  if (!profile) {
    if (!!claimBusinessId) {
      return (
        <ProfileContext.Provider value={missingProfileContext}>
          <ClaimBusinessProviderInner businessId={claimBusinessId}>
            {children}
          </ClaimBusinessProviderInner>
        </ProfileContext.Provider>
      );
    } else {
      return (
        <ProfileContext.Provider value={missingProfileContext}>
          <BusinessContext.Provider value={missingBusinessContext}>
            {children}
          </BusinessContext.Provider>
        </ProfileContext.Provider>
      );
    }
  }

  return (
    <ProfileContext.Provider
      value={{
        profileId: profile!.id,
        profile: profile,
        isLoading,
      }}
    >
      <BusinessProvider>
        <BusinessCreditsProvider>{children}</BusinessCreditsProvider>
      </BusinessProvider>
    </ProfileContext.Provider>
  );
}

function BusinessCreditsProvider({ children }: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const businessContext = useContext(BusinessContext);

  const currentUser = useUser(true);

  const creditsRef = query<BusinessCreditsDocument>(
    collection(fs, "businessCredits").withConverter<BusinessCreditsDocument>(
      businessCreditsConverter
    ),
    where("businessId", "==", businessContext?.businessId)
  );
  const [credits /* isLoading */, , error] = useCollectionData(creditsRef);

  useEffect(() => {
    logger.updateFirestoreContext({ businessId: null });
    if (!!businessContext) {
      logger.updateFirestoreContext({ businessId: businessContext.businessId });

      window.Appcues.identify(currentUser?.uid, {
        email: currentUser?.email,
        name: currentUser?.displayName,
        tier: businessContext?.business?.tier,
        backendUrl: backendConfig.domainUrl,
      });
    }
  }, [error, currentUser, businessContext]);

  if (!credits || credits.length === 0) {
    if (!!error) {
      logger.error(
        LogSource.FIRESTORE,
        "Error retreiving Business Credits:",
        error
      );
    }
    return <>{children}</>;
  } else {
    if (credits.length > 1) {
      logger.warn(LogSource.FIRESTORE, "Duplicate Credits Found:", {
        message: `${[...credits]}`,
      });
    }

    return (
      <BusinessContext.Provider
        value={{ ...businessContext, credits: credits[0].amount }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }
}

function BusinessProvider({ children }: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const profileId = useProfileId();
  const claimBusinessId = useSearchParam(SearchParam.claimBusiness);

  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>();
  const [businessesDetails, setBusinessesDetails] = useState<BusinessWithDetails[]>([]);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const junctionRef = query<ProfileBusinessDocument>(
    collection(
      fs,
      "junctionProfileBusiness"
    ).withConverter<ProfileBusinessDocument>(profileBusinessConverter),
    where("profileId", "==", profileId)
  );
  const [junctions, junctionIsLoading, junctionError] =
    useCollectionData(junctionRef);

  useEffect(() => {
    if (junctions?.length) {
      setSelectedBusinessId(junctions[0]?.businessId);

      const fetchDetails = async () => {
        setIsLoadingDetails(true);
        const enrichedBusinesses = await Promise.all(
          junctions.map(async (business) => {
            // Fetch asset names related to this business
            const assetNameQuery = query(
              collection(fs, "assetNames"),
              where("businessId", "==", business.businessId),
              where("selectedForBrandKit", "==", true)
            );
            const assetNameSnapshot = await getDocs(assetNameQuery);
            const assetName = assetNameSnapshot.docs.map((doc) =>
              doc.data()
            )[0];

            // Fetch asset logos related to this business
            const assetLogoQuery = query(
              collection(fs, "assetLogos"),
              where("businessId", "==", business.businessId),
              where("selectedForBrandKit", "==", true)
            );
            const assetLogoSnapshot = await getDocs(assetLogoQuery);
            const assetLogo = assetLogoSnapshot.docs.map((doc) =>
              doc.data()
            )[0];

            // Fetch credits related to this business
            const creditsQuery = query(
              collection(fs, "businessCredits"),
              where("businessId", "==", business.businessId)
            );
            const creditsSnapshot = await getDocs(creditsQuery);
            const credits = creditsSnapshot.docs.map((doc) => doc.data())[0];

            return {
              ...business,
              assetName,
              assetLogo,
              credits: credits?.amount || 0,
            };
          })
        );
        setBusinessesDetails(enrichedBusinesses);
        setIsLoadingDetails(false);
      };

      fetchDetails();
    } else {
      setIsLoadingDetails(false);
    }
  }, [junctions, fs]);

  useEffect(() => {
    if (junctions?.length) {
      setSelectedBusinessId(junctions![0]!.businessId);
    }
  }, [junctions]);

  // Use businessId from URL param before attempting to read businessId from juntion.
  if (!!claimBusinessId) {
    return (
      <ClaimBusinessProviderInner businessId={claimBusinessId}>
        {children}
      </ClaimBusinessProviderInner>
    );
  }

  if (!!junctionError) {
    logger.error(
      LogSource.FIRESTORE,
      "Error retreiving ProfileBusinessJunction:",
      junctionError
    );
    return (
      <BusinessContext.Provider value={missingBusinessContext}>
        {children}
      </BusinessContext.Provider>
    );
  }

  if (junctionIsLoading || isLoadingDetails) {
    return (
      <BusinessContext.Provider value={loadingBusinessContext}>
        {children}
      </BusinessContext.Provider>
    );
  }

  if (!junctions!.length) {
    return (
      <BusinessContext.Provider value={missingBusinessContext}>
        {children}
      </BusinessContext.Provider>
    );
  }

  if (selectedBusinessId) {
    return (
      <BusinessProviderInner
        businessId={selectedBusinessId}
        businesses={businessesDetails}
        setBusinessesDetails={setBusinessesDetails}
        setSelectedBusinessId={setSelectedBusinessId}
      >
        {children}
      </BusinessProviderInner>
    );
  }

  return <></>;
}

interface BusinessProviderInnerProps {
  businessId: string;
  businesses?: BusinessWithDetails[];
  setSelectedBusinessId?: any;
  setBusinessesDetails?: any;
}
function BusinessProviderInner({
  businessId,
  children,
  businesses,
  setSelectedBusinessId,
  setBusinessesDetails,
}: PropsWithChildren<BusinessProviderInnerProps>) {
  const fs = useContext(FirestoreContext);

  const businessRef = doc(
    fs,
    "businesses",
    businessId
  ).withConverter<BusinessDocument>(businessConverter);
  const [business, isLoading, error] =
    useDocumentData<BusinessDocument>(businessRef);

  useEffect(() => {
    if (!!business) {
      logger.updateFirestoreContext({ businessId: business.id });
    } else {
      logger.updateFirestoreContext({ businessId: null });
    }
  }, [business]);

  if (!!error) {
    logger.error(LogSource.FIRESTORE, "Error retreiving Business:", error);
    return (
      <BusinessContext.Provider
        value={{
          ...missingBusinessContext,
          businessId,
          businesses,
          setSelectedBusinessId,
          setBusinessesDetails,
        }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <BusinessContext.Provider
        value={{
          ...loadingBusinessContext,
          businessId,
          businesses,
          setSelectedBusinessId,
          setBusinessesDetails,
        }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  if (!business) {
    logger.error(LogSource.FIRESTORE, `Missing Business for ID: ${businessId}`);
    return (
      <BusinessContext.Provider
        value={{
          ...missingBusinessContext,
          businessId,
          businesses,
          setSelectedBusinessId,
          setBusinessesDetails,
        }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  return (
    <BusinessContext.Provider
      value={{
        businessId,
        business,
        isLoading,
        businesses,
        setSelectedBusinessId,
        setBusinessesDetails,
        credits: null,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
}

function ClaimBusinessProviderInner({
  businessId,
  children,
}: PropsWithChildren<BusinessProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const [, setClaimBusinessId] = useSearchParamState(SearchParam.claimBusiness);

  const businessRef = doc(
    fs,
    "businesses",
    businessId
  ).withConverter<BusinessDocument>(businessConverter);
  const [business, isLoading, error] =
    useDocumentData<BusinessDocument>(businessRef);

  useEffect(() => {
    if (!!business) {
      logger.updateFirestoreContext({ businessId: business.id });
    } else {
      logger.updateFirestoreContext({ businessId: null });
    }
  }, [business]);

  // If any business was already linked to the profile,
  // it will be repopulated in the context after removing the param.
  useEffect(() => {
    if (!!error || isLoading) {
      return;
    }
    if (!business) {
      logger.error(
        LogSource.FIRESTORE,
        `Missing Business to claim for ID: ${businessId}`
      );
      setClaimBusinessId(null);
    } else if (business.isClaimed) {
      // Using warn instead of error, because its difficult to tell if this is unintentional,
      // or just the race condition of firestore updating before the param is removed.
      logger.warn(
        LogSource.FIRESTORE,
        `Business ${businessId} is already claimed`
      );
      setClaimBusinessId(null);
    }
  }, [error, isLoading, setClaimBusinessId, businessId, business]);

  if (!!error) {
    logger.error(
      LogSource.FIRESTORE,
      "Error retreiving Business to claim:",
      error
    );
    return (
      <BusinessContext.Provider
        value={{ ...missingBusinessContext, businessId }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <BusinessContext.Provider
        value={{ ...loadingBusinessContext, businessId }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  if (!business || business.isClaimed) {
    // Will modify search params away in in above effect.
    return <></>;
  }

  return (
    <BusinessContext.Provider
      value={{
        businessId,
        business,
        isLoading,
        credits: null,
        setSelectedBusinessId: () => {},
        setBusinessesDetails: () => {},
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
}
