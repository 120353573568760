import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Resource } from "src/lib/types";

type AddResourceModalProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  newResource: Resource;
  setNewResource: (resource: Resource) => void;
  addResource: () => void;
  handleClose: () => void;
};

export function AddResourceModal({
  isOpen,
  onOpenChange,
  newResource,
  setNewResource,
  addResource,
  handleClose,
}: AddResourceModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle="bodyLarge">Add New Resource</Text>
          </Center>
        </ModalHeader>
        <Box pt="2">
          <ModalCloseButton />
        </Box>
        <ModalBody>
        <Flex flexDirection="column" gap="2">
          <label htmlFor="new-url" className="text-sm font-bold">
            Resource URL
          </label>
          <Input
            id="new-url"
            placeholder="Resource URL"
            value={newResource.url || ""}
            onChange={(e) =>
              setNewResource({ ...newResource, url: e.target.value })
            }
            aria-label="New resource URL"
            className="bg-background"
          />
          <label htmlFor="new-title" className="text-sm font-bold">
            Resource Title
          </label>
          <Input
            id="new-title"
            placeholder="Resource Title"
            value={newResource.title || ""}
            onChange={(e) =>
              setNewResource({ ...newResource, title: e.target.value })
            }
            aria-label="New resource title"
            className="bg-background"
          />
          <label htmlFor="new-description" className="text-sm font-bold">
            Resource Description
          </label>
          <Textarea
            id="new-description"
            placeholder="Resource Description"
            value={newResource.description || ""}
            onChange={(e) =>
              setNewResource({
                ...newResource,
                description: e.target.value,
              })
            }
            aria-label="New resource description"
            className="bg-background"
          />
        </Flex>
        <Button
          mt="2"
          colorScheme='primary'
          variant='fill'
          onClick={addResource}
          disabled={
            !newResource.url || !newResource.title || !newResource.description
          }
        >
          Add Resource
        </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
