export const MAX_SELECTED_AUDIENCES = 3;
export const MAX_SELECTED_COLORS = 1;
export const MAX_SELECTED_FONTS = 1;
export const MAX_SELECTED_LOGOS = 1;
export const MAX_SELECTED_NAMES = 1;
export const MAX_SELECTED_SLOGANS = 1;
export const MAX_SELECTED_BRAND_STORIES = 1;
export const MAX_SELECTED_VALUES = 3;

//references AssetTypes
export const OPTIONAL_ASSETS = ["font", "brand", "brandAsset"]

export const DEFAULT_GENERATED_AUDIENCES = 3;
export const DEFAULT_GENERATED_COLORS = 3;
export const DEFAULT_GENERATED_FONTS = 3;
export const DEFAULT_GENERATED_LOGOS = 5;
export const DEFAULT_GENERATED_NAMES = 3;
export const DEFAULT_GENERATED_SLOGANS = 3;
export const DEFAULT_GENERATED_BRAND_STORIES = 3;
export const DEFAULT_GENERATED_VALUES = 3;
export const DEFAULT_GENERATED_ACTIONS = 1;
