import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { CopilotChat, useCopilotChatSuggestions } from "@copilotkit/react-ui";
import { useEffect, useState } from "react";
import { Path } from "src/nav";
import { HeaderFooterPage } from "src/pages/wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "src/pages/wrappers/RequireBusiness";
import "@copilotkit/react-ui/styles.css";
import { useUser } from "src/auth";
import {
  useBusinessId,
} from "src/db";
import { useApi } from "src/api";
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

export function SingleAgentAppPage() {
  return (
    <CopilotKit runtimeUrl="https://copilot-single-agent-app-651903022604.us-west2.run.app/api/copilot">
      <HeaderFooterPage pageTitle="Single Agent" backTo={Path.home}>
        <RequireBusinessWithSignup>
          <SingleAgentAppPageContent />
        </RequireBusinessWithSignup>
      </HeaderFooterPage>
    </CopilotKit>
  );
}

// Keyframes for spinner animation
const spinnerKeyframes = `
 @keyframes spin {
     to { transform: rotate(360deg); }
 }
 `;

type fileInfo = {
  name: string;
  url: string;
};

const SingleAgentAppPageContent = () => {
  const [cdna, setCdna] = useState<unknown>(null);

  const [api, surfaceKnownErrors] = useApi();

  // const {
  //   collection: brandUploads,
  //   isLoading: logoAssetsAreLoading,
  //   error: collectionError,
  // } = useAssetContext(AssetTypes.brandAsset);

  // const files = brandUploads?.map((asset) => ({
  //   name: asset.value.brand.name,
  //   url: asset.value.brand.fileInfo.fileUrl,
  // }));
  const files: fileInfo[] = [];

  const currentUser = useUser(true);
  const businessId = useBusinessId();

  // Define Copilot readable state
  useCopilotReadable({
    description:
      "User's business profile data, including branding information such as business name, logo, target audience, etc.",
    value: cdna,
  });

  useCopilotReadable({
    description: "User ID of the currently logged in user.",
    value: currentUser?.uid,
  });

  // CopilotKit suggestion configuration
  useCopilotChatSuggestions({
    instructions: "Suggest the most relevant next actions.",
    minSuggestions: 1,
    maxSuggestions: 2,
  });

  useEffect(() => {
    const fetchCDNA = async () => {
      if (!currentUser || !businessId) {
        return;
      }
      api
        .getCDNA(
          {
            userId: currentUser?.uid,
            businessId,
          },
          surfaceKnownErrors
        )
        .then((response) => {
          const data: any = response.data;
          setCdna(data?.cdna);
        });
    };

    fetchCDNA();
  }, [currentUser, businessId]);

  return (
    <Flex direction="column" margin="20px">
      <style>{spinnerKeyframes}</style>
      {/* Copilot Chat */}
      <CopilotChat
        instructions={
          "You are a helpful business brand assistant, called Brandi. Answer user's question to the best of your knowledge..."
        }
        labels={{
          title: "Copilot Brandi",
          initial: "Hello, how can I help you today?",
        }}
      />

      <Flex direction="column" marginTop="40px">
        {/* List of Files */}
        {files.length === 0 ? (
          <Flex align="center" flexDirection="column" gap="4px">
            <Text>No Brand Files uploaded yet.</Text>
            {/* <AddBrandAssetButton variant="ghost" colorScheme="primary">
              +&nbsp;Add Your Own
            </AddBrandAssetButton> */}
          </Flex>
        ) : (
          <Table variant="simple" width="100%">
            <Thead>
              <Tr>
                <Th>File Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {files.map((file, index) => (
                <Tr
                  key={file.name}
                  bg={index % 2 === 0 ? "#f9f9f9" : "#ffffff"}
                >
                  <Td>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "underline",
                        color: "#1e90ff",
                      }}
                    >
                      {file.name}
                    </a>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Flex>
    </Flex>
  );
};
