// @ts-nocheck
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  useCoAgent,
  useCoAgentStateRender,
  useCopilotAction,
} from "@copilotkit/react-core";
import { useModelSelectorContext } from "src/lib/model-selector-provider";
import { AgentState, Resource } from "src/lib/types";
import { Progress } from "./Progress";
import {
  Button,
  Flex,
  Input,
  Textarea,
  Card,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import { Resources } from "./Resources";
import { AddResourceModal } from "./AddResourceModal";
import { EditResourceModal } from "./EditResourceModal";

export function ResearchCanvas() {
  const { model, agent } = useModelSelectorContext();

  const { state, setState } = useCoAgent<AgentState>({
    name: agent,
    initialState: {
      model,
    },
  });

  useCoAgentStateRender({
    name: agent,
    render: ({ state, nodeName, status }) => {
      if (!state.logs || state.logs.length === 0) {
        return null;
      }
      return <Progress logs={state.logs} />;
    },
  });

  useCopilotAction({
    name: "DeleteResources",
    description:
      "Prompt the user for resource delete confirmation, and then perform resource deletion",
    available: "remote",
    parameters: [
      {
        name: "urls",
        type: "string[]",
      },
    ],
    renderAndWait: ({ args, status, handler }: any) => {
      return (
        <Flex
          direction="column"
          data-test-id="delete-resource-generative-ui-container"
        >
          <Text fontWeight="bold" fontSize="lg" mb="2">
            Delete these resources?
          </Text>
          <Resources
            resources={resources.filter((resource) =>
              (args.urls || []).includes(resource.url)
            )}
            customWidth={200}
          />
          {status === "executing" && (
            <Flex mt="4" justify="start" gap="2">
              <Button
                onClick={() => handler("NO")}
                variant="outline"
                colorScheme="primary"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                data-test-id="button-delete"
                onClick={() => handler("YES")}
                colorScheme="primary"
                size="sm"
              >
                Delete
              </Button>
            </Flex>
          )}
        </Flex>
      );
    },
  });

  const resources: Resource[] = state.resources || [];
  const setResources = (resources: Resource[]) => {
    setState({ ...state, resources });
  };

  const [newResource, setNewResource] = useState<Resource>({
    url: "",
    title: "",
    description: "",
  });
  const [isAddResourceOpen, setIsAddResourceOpen] = useState(false);

  const addResource = () => {
    if (newResource.url) {
      setResources([...resources, { ...newResource }]);
      setNewResource({ url: "", title: "", description: "" });
      setIsAddResourceOpen(false);
    }
  };

  const removeResource = (url: string) => {
    setResources(
      resources.filter((resource: Resource) => resource.url !== url)
    );
  };

  const [editResource, setEditResource] = useState<Resource | null>(null);
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);
  const [isEditResourceOpen, setIsEditResourceOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleCardClick = (resource: Resource) => {
    setEditResource({ ...resource });
    setOriginalUrl(resource.url);
    setIsEditResourceOpen(true);
  };

  const updateResource = () => {
    if (editResource && originalUrl) {
      setResources(
        resources.map((resource) =>
          resource.url === originalUrl ? { ...editResource } : resource
        )
      );
      setEditResource(null);
      setOriginalUrl(null);
      setIsEditResourceOpen(false);
    }
  };

  return (
    <>
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Card m="2" p="4">
            <Text fontSize="lg" fontWeight="medium" mb="3" color="primary">
              Report Topic
            </Text>
            <Input
              placeholder="Enter your report topic"
              value={state.research_question || ""}
              onChange={(e) =>
                setState({ ...state, research_question: e.target.value })
              }
              aria-label="Report topic"
              className="bg-background px-6 py-8 border-0 shadow-none rounded-xl text-md font-extralight focus-visible:ring-0 placeholder:text-slate-400"
            />
          </Card>

          <Card m="2" p="4">
            <Flex my="4" flexDirection="column" gap="4">
              <Text fontSize="lg" fontWeight="medium" mb="3" color="primary">
                Resources
              </Text>
              <Button
                colorScheme="primary"
                variant="fill"
                onClick={() => setIsAddResourceOpen(true)}
              >
                Add Resource
              </Button>
            </Flex>
            {resources.length === 0 && (
              <div className="text-sm text-slate-400">
                Click the button above to add resources.
              </div>
            )}

            {resources.length !== 0 && (
              <Resources
                resources={resources}
                handleCardClick={handleCardClick}
                removeResource={removeResource}
              />
            )}
          </Card>

          <Card m="2" p="4">
            <Flex flexDirection="column" gap="4" my="2">
              <Text fontSize="lg" fontWeight="medium" mb="3" color="primary">
                Report Draft
              </Text>
              <Button
                colorScheme="primary"
                variant="fill"
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? "Preview" : "Edit"}
              </Button>
            </Flex>
            {isEditing ? (
              <Textarea
                data-test-id="research-draft"
                placeholder="Write your report draft here"
                value={state.report || ""}
                onChange={(e) => setState({ ...state, report: e.target.value })}
                rows={10}
                aria-label="Report draft"
                className="bg-background px-6 py-8 border-0 shadow-none rounded-xl text-md font-extralight focus-visible:ring-0 placeholder:text-slate-400 overflow-auto"
                style={{ height: "calc(100% - 40px)", resize: "none" }}
              />
            ) : (
              <Flex
                direction="column"
                bg="background"
                p="6"
                rounded="xl"
                overflow="auto"
                className="prose"
              >
                <ReactMarkdown
                  components={{
                    h1: ({ node, ...props }) => (
                      <Text
                        fontSize="3xl"
                        fontWeight="bold"
                        my="4"
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        my="3"
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <Text fontSize="xl" fontWeight="bold" my="2" {...props} />
                    ),
                    p: ({ node, ...props }) => <Text my="2" {...props} />,
                    ul: ({ node, ...props }) => (
                      <UnorderedList ml="4" my="2" {...props} />
                    ),
                    ol: ({ node, ...props }) => (
                      <OrderedList ml="4" my="2" {...props} />
                    ),
                    li: ({ node, ...props }) => <ListItem my="1" {...props} />,
                    blockquote: ({ node, ...props }) => (
                      <Text
                        as="blockquote"
                        borderLeft="4px"
                        borderColor="gray.300"
                        pl="4"
                        my="2"
                      />
                    ),
                  }}
                >
                  {state.report || ""}
                </ReactMarkdown>
              </Flex>
            )}
          </Card>
        </Flex>
      </Flex>
      <AddResourceModal
        isOpen={isAddResourceOpen}
        onOpenChange={setIsAddResourceOpen}
        newResource={newResource}
        setNewResource={setNewResource}
        addResource={addResource}
        handleClose={() => setIsAddResourceOpen(false)}
      />
      <EditResourceModal
        isOpen={isEditResourceOpen}
        onOpenChange={setIsEditResourceOpen}
        editResource={editResource}
        setEditResource={setEditResource}
        updateResource={updateResource}
      />
    </>
  );
}
