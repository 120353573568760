import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useBusiness, useBusinesses, useNotifications } from "src/db/hooks";
import {
  ArrowLeft,
  Bell,
  BellActive,
  CaretDown,
  Chat,
  LogOut,
  talawa,
  User,
} from "src/images";
import { Path, useNavigateWithParams } from "src/nav";
import {
  menuItems,
  settingsMenuItem,
} from "src/pages/wrappers/BottomNavigationBar/items";
import BrandSwitcherDropdown from "../BrandSwitcherDropdown";
import NotificationsModal from "src/components/NotificationsModal";
import { PermanentChat } from "src/components/PermanentChat";
import { BusinessWithDetails } from "src/db/model/profileBusiness";

interface DesktopHeaderMenuProps extends BoxProps {
  pageTitle?: string;
  // if missing, no back button.
  backTo?: Path;
  // Optional element in the top right corner
  topRightContent?: ReactNode;
  // Optional element in the top right corner
  banner?: ReactNode;
}

const DesktopHeaderMenu = ({
  children,
  backTo,
  pageTitle,
}: DesktopHeaderMenuProps) => {
  const location = useLocation();
  const navigate = useNavigateWithParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsModal, setNotificationsModal] = useState<boolean>(false);

  const handleNavigationClick = (item: MenuItem) => {
    navigate({ to: item.path });
  };

  const settingsActive = settingsMenuItem.path === location.pathname;

  const businesses = useBusinesses();
  const business = useBusiness();

  const selectedBusinessDetails = businesses?.find(
    (b) => b.businessId === business?.id
  );

  const noBrandNameBusinesses = businesses?.filter(
    (businessData) => !businessData?.assetName?.assetData?.assetValue?.name
  );

  const noBrandNameIndex = (noBrandNameBusinesses || []).findIndex((brand: BusinessWithDetails) => brand.businessId === selectedBusinessDetails?.businessId);
  
  const selectedBusinessName = noBrandNameIndex >= 0 ? `Unnamed ${noBrandNameIndex + 1}` : selectedBusinessDetails?.assetName?.assetData?.assetValue.name;

  const selectedBusinessLogo =
    selectedBusinessDetails?.assetLogo?.assetData?.assetValue.logo.imageInfo
      .imageUrl;

  const notifications = useNotifications();

  const unreadNotifications =
    Boolean(notifications?.length) && notifications?.some((n) => !n.read);

  const handleOpen = () => setMenuOpen(true);
  const handleClose = () => setMenuOpen(false);

  const brandSwitcher = () => (
    <>
      {/* Overlay */}
      {menuOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="250px"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)"
          zIndex={1}
          onClick={handleClose}
        />
      )}
      <Menu onOpen={handleOpen} onClose={handleClose}>
        <MenuButton disabled={!businesses} w="full">
          <Button variant="whiteGhost" w="full" borderRadius="60px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Box display="flex" alignItems="center" gap="16px">
                <Avatar
                  name={selectedBusinessName}
                  src={selectedBusinessLogo}
                  boxSize="32px"
                />
                <Text
                  color="primary.veryDark"
                  w="100px"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {selectedBusinessName}
                </Text>
              </Box>
              <IconButton
                _hover={{}}
                _active={{}}
                icon={
                  <Image src={CaretDown} alt="brand switcher" height="20px" />
                }
                aria-label="Menu"
                bg={"transparent"}
              />
            </Box>
          </Button>
        </MenuButton>
        <BrandSwitcherDropdown businesses={businesses} width="240px" noBrandNameBusinesses={noBrandNameBusinesses} />
      </Menu>
    </>
  );

  const openNotifications = () => {
    setNotificationsModal(true);
  };

  return (
    <Flex bg="white">
      {/* Sidebar */}
      <Box
        w="250px"
        bg="grayscale.offwhite"
        boxShadow="md"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py={6}
        px={4}
        position="fixed"
        height="100vh"
      >
        {/* Top Section */}
        <VStack align="flex-start" spacing={6}>
          {/* Logo */}
          <Box
            display="flex"
            alignItems="center"
            w="100%"
            p="4"
            justifyContent="center"
          >
            <Link to={Path.newLanding}>
              <Image
                src={talawa}
                alt="Talawa"
                mixBlendMode="multiply"
                height="20px"
              />
            </Link>
          </Box>

          {/* Brand Switcher */}
          <VStack align="flex-start" spacing={4} w="full">
            {businesses && brandSwitcher()}
          </VStack>

          {/* Menu Items */}
          <VStack align="flex-start" spacing={4} w="full">
            {menuItems
              .filter((item) => !item.onlyMobile)
              .map((item) => {
                const active = item.path === location.pathname;
                return (
                  <Button
                    color={active ? "white" : "gray.medium"}
                    variant="fill"
                    justifyContent="flex-start"
                    bg={active ? "primary.dark" : "transparent"}
                    _hover={{}}
                    _active={{}}
                    w="full"
                    borderRadius="8px"
                    onClick={() => handleNavigationClick(item)}
                    isDisabled={item.requireBusiness && !business}
                    leftIcon={
                      <Image
                        src={active ? item.whiteIcon : item.icon}
                        alt={item.label}
                        color="white"
                        height="28px"
                      />
                    }
                    key={item.id}
                  >
                    {item.label}
                  </Button>
                );
              })}
          </VStack>
        </VStack>

        {/* Brand Settings */}
        <Flex
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor="primary.veryDark"
          p="4"
          flexDirection="column"
          alignItems="flex-start"
          gap="8px"
        >
          <Box pl="8px">
            <PermanentChat
              position="relative"
              boxSize="33px"
              width="auto"
              bottom="auto"
              margin="0"
            >
              <Button variant="fill" leftIcon={<Image src={Chat} alt="brandi" />}>
                Ask Brandi
              </Button>
            </PermanentChat>
          </Box>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            w="full"
            color={settingsActive ? "primary.dark" : "gray.medium"}
            onClick={() => handleNavigationClick(settingsMenuItem)}
            isDisabled={settingsMenuItem.requireBusiness && !business}
            leftIcon={
              <Image
                src={
                  settingsActive
                    ? settingsMenuItem.activeIcon
                    : settingsMenuItem.icon
                }
                alt={settingsMenuItem.label}
                color="white"
                height="24px"
              />
            }
          >
            Brand Settings
          </Button>
        </Flex>
      </Box>

      {/* Main Content */}
      <Flex flex={1} flexDirection="column" p={6} ml="250px" w="75%">
        {/* Header */}
        <Flex justify="space-between" align="center" mb={4} ml="4">
          <Text textStyle="titleForPageHeader">{pageTitle}</Text>

          <HStack spacing={4}>
            <IconButton
              icon={
                <Image
                  src={unreadNotifications ? BellActive : Bell}
                  alt="notifications"
                  height="28px"
                />
              }
              aria-label="Notifications"
              onClick={openNotifications}
              bg={"transparent"}
            />
            <Link to={Path.account}>
              <IconButton
                icon={<Image src={User} alt="account" height="28px" />}
                aria-label="Account"
                bg={"transparent"}
              />
            </Link>
            <Link to={Path.logout}>
              <Button
                variant="fill"
                size="sm"
                leftIcon={<Image src={LogOut} alt="log out" height="22px" />}
                onClick={() => navigate({ to: Path.logout })}
              >
                Log Out
              </Button>
            </Link>
          </HStack>
        </Flex>

        {/* Main Content Area */}
        <Box
          flex={1}
          borderRadius="md"
          p={4}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {backTo && (
            <Box
              display="flex"
              alignItems="center"
            >
              <Flex cursor="pointer" onClick={() => navigate({ to: backTo })} alignItems="center" gap="8px">
                <IconButton
                  _hover={{}}
                  _active={{}}
                  icon={<Image src={ArrowLeft} alt="back" height="20px" />}
                  aria-label="Back"
                  bg={"transparent"}
                />
                <Text color="gray.medium" fontSize="13px">
                  back
                </Text>
              </Flex>
            </Box>
          )}
          {children}
        </Box>
      </Flex>
      {notificationsModal && (
        <NotificationsModal
          isOpen={notificationsModal}
          handleClose={() => setNotificationsModal(false)}
          notifications={notifications}
        />
      )}
    </Flex>
  );
};

export default DesktopHeaderMenu;
