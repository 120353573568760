import { Box, Flex, IconButton, Image, Progress, Text } from "@chakra-ui/react";
import { AddBrandAssetButton } from "src/components/assets/addAsset/AddBrandAsset";
import {
  ASSET_ACCEPT,
  CARD_CONTENT_BY_TAG,
} from "src/components/assets/brandAssetsCard/constants";
import FileViewer from "./FileViewer";
import { PlusFilled } from "src/images";
import { AssetTypes, BrandAssetTypes } from "src/db/model/asset";
import { useAssetContext } from "src/db";

const AssetFolderContent = ({
  tag,
  parent,
}: {
  tag: BrandAssetTypes;
  parent?: BrandAssetTypes;
}) => {
  const {
    collection: brandAssets,
    isLoading: logoAssetsAreLoading,
    error: collectionError,
  } = useAssetContext(AssetTypes.brandAsset);

  const files = parent
    ? (brandAssets as Record<string, any>)[parent].children[tag]
    : (brandAssets as Record<string, any>)[tag];

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  }

  if (logoAssetsAreLoading) {
    return (
      <>
        <Box py={8} px="4">
          <Progress size="lg" isIndeterminate={true} />
        </Box>
      </>
    );
  }

  return (
   <Box bg="gray.offwhite">
      <Flex my="12px" flexDirection="column">
        <Flex my="2" justifyContent="space-between">
          <Box>
            <Text textStyle="body" fontSize="16px" fontWeight="bold">
              {CARD_CONTENT_BY_TAG[tag]?.title}
            </Text>
          </Box>
          <Box>
            <AddBrandAssetButton selectedTag={tag} accept={ASSET_ACCEPT[tag]}>
              <IconButton
                aria-label="Add new asset"
                boxSize="32px"
                isRound={true}
                bg="grayscale.white"
                icon={<Image src={PlusFilled} />}
              />
            </AddBrandAssetButton>
          </Box>
        </Flex>
        <Flex maxW="250px">
          <Text textStyle="body" mb={2} fontSize="13px" color="gray.medium">
            {CARD_CONTENT_BY_TAG[tag]?.subtitle}
          </Text>
        </Flex>
      </Flex>
      <Flex align="center" direction="column" justify="start" mb={4}>
        <FileViewer files={files} />
      </Flex>
    </Box>
  );
};

export default AssetFolderContent;
