import { useCallback } from "react";

import { useApi } from "src/api";
import { useUser } from "src/auth";
import { AssetDocument, BrandAssetDocument } from "src/db";
import { talawaCM } from "src/firebaseAndBackendConfig";

export type MarkPending = (isPending: boolean) => void;
export type AssetActionRunner<Asset = AssetDocument> = (
  asset: Asset,
  markPending?: MarkPending
) => Promise<any>;

export type BrandAssetActionRunner<Asset = BrandAssetDocument> = (
  assets: Asset | Asset[],
  markPending?: MarkPending
) => Promise<any>;

export type UpdateBrandAssetActionRunner<Asset = BrandAssetDocument> = (
  assets: Asset | Asset[],
  data: { fileName?: string; tags?: string[] },
  markPending?: MarkPending
) => Promise<any>;

const markNothing = (isPending: boolean) => {};
const requestBase = (asset: AssetDocument) => {
  return {
    businessId: asset.businessId,
    assetType: asset.assetType,
    assetId: asset.id,
  };
};

export function useSelectAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback(
    (asset, markPending = markNothing) => {
      markPending(true);
      return api
        .selectAsset(
          {
            ...requestBase(asset),
            selectedForBrandKit: true,
          },
          surfaceKnownErrors
        )
        .then(() => {
          markPending(false);
        });
    },
    [api, surfaceKnownErrors]
  );
}

export function useUnselectAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback(
    (asset, markPending = markNothing) => {
      markPending(true);
      return api
        .selectAsset(
          {
            ...requestBase(asset),
            selectedForBrandKit: false,
          },
          surfaceKnownErrors
        )
        .then(() => {
          markPending(false);
        });
    },
    [api, surfaceKnownErrors]
  );
}

export function useRemoveAsset(): AssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback(
    (asset, markPending = markNothing) => {
      markPending(true);
      return api
        .reviewAsset(
          {
            ...requestBase(asset),
            review: "NEGATIVE",
          },
          surfaceKnownErrors
        )
        .then(() => {
          markPending(false);
        });
    },
    [api, surfaceKnownErrors]
  );
}

export function useRemoveBrandAsset(): BrandAssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  const currentUser = useUser(true);

  const handleDeleteFile = async (fileName: string) => {
    if (!currentUser) return;

    try {
      // Construct the query payload to find related contexts
      const queryPayload = {
        queries: [],
        rendering: ["raw"],
        metadata: {
          user_id: currentUser.uid,
          "knowledge-base": "business",
          source: "user",
          file_ref: `${talawaCM.storageBucketFolder}/${currentUser.uid}/${fileName}`,
        },
        limit: 100,
      };

      // Query the context database for related contexts
      const queryResponse = await fetch(
        `${talawaCM.apiHost}/v1/contexts/query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${talawaCM.token}`,
          },
          body: JSON.stringify(queryPayload),
        }
      );

      if (!queryResponse.ok) {
        console.error(
          `Failed to query context database: ${queryResponse.statusText}`
        );
        return;
      }

      const contexts = await queryResponse.json();
      if (contexts.length === 0) {
        console.error("No response from context query.");
        return;
      }

      if (!Array.isArray(contexts)) {
        console.error(
          "Unexpected response format from context query:",
          contexts
        );
        return;
      }

      // Iterate over each context and delete it
      for (const context of contexts) {
        const docIdToDelete = context.metadata.doc_id;
        if (!docIdToDelete) {
          console.warn("Context without ID encountered:", context);
          continue;
        }
        console.log(`Deleting context with DOC ID: ${docIdToDelete}`);

        const deleteResponse = await fetch(
          `${talawaCM.apiHost}/v1/contexts/${docIdToDelete}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${talawaCM.token}`,
            },
          }
        );

        if (!deleteResponse.ok) {
          console.error(
            `Failed to delete context with DOC ID ${docIdToDelete}: ${deleteResponse.statusText}`
          );
          // Optionally, you can choose to continue deleting other contexts or halt the process
        } else {
          console.log(`Deleted context with DOC ID: ${docIdToDelete}`);
        }
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return useCallback(
    async (assets, markPending = markNothing) => {
      markPending(true);

      const deleteAsset = async (asset: BrandAssetDocument) => {
        // Convert the object to FormData
        const formData = new FormData();
        formData.append("businessId", asset.businessId);
        formData.append("storageId", asset.value.brand.fileInfo.storageId);
        formData.append("collectionId", asset.id);

        await api.deleteBrandAsset(formData, surfaceKnownErrors);
        await handleDeleteFile(asset.value.brand.name);
      };

      const assetsArray = Array.isArray(assets) ? assets : [assets];
      for (const asset of assetsArray) {
        await deleteAsset(asset);
      }

      markPending(false);
    },
    [api, surfaceKnownErrors]
  );
}

export function useUpdateBrandAsset(): UpdateBrandAssetActionRunner {
  const [api, surfaceKnownErrors] = useApi();
  return useCallback(
    async (assets, { fileName, tags }, markPending = markNothing) => {
      markPending(true);

      const updateAsset = async (asset: BrandAssetDocument) => {
        const formData = new FormData();
        formData.append("businessId", asset.businessId);

        if (fileName) {
          formData.append("fileName", fileName);
        }

        if (tags) {
          tags.forEach((tag) => {
            formData.append("tags", tag);
          });
        }

        await api.updateBrandAsset(asset.id, formData, surfaceKnownErrors);
      };

      const assetsArray = Array.isArray(assets) ? assets : [assets];
      for (const asset of assetsArray) {
        await updateAsset(asset);
      }

      markPending(false);
    },
    [api, surfaceKnownErrors]
  );
}
