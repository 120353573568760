import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  VStack,
  Collapse,
  Box,
  Text,
  IconButton,
  Icon,
  Image,
} from "@chakra-ui/react";
import { ASSET_TAGS } from "../constants";
import { MdClose } from "react-icons/md";
import { FaFolder } from "react-icons/fa6";
import { CaretDown, CaretUp } from "src/images";
import { useUpdateBrandAsset } from "../../actions/hooks";
import { BrandAssetDocument } from "src/db";

interface EditTagsModalProps {
  assets: BrandAssetDocument | BrandAssetDocument[];
  onClose: () => void;
}

const EditTagsModal: React.FC<EditTagsModalProps> = ({
  assets,
  onClose,
}) => {
  const [selectedTags, setSelectedTags] = useState<{ [key: string]: boolean }>(() => {
    const tags = Array.isArray(assets)
      ? assets.flatMap(asset => asset.value.brand.tags)
      : assets.value.brand.tags;
    return tags.reduce((acc: any, tag: string) => {
      acc[tag] = true;
      return acc;
    }, {});
  });
  const [closedFolders, setClosedFolders] = useState<{ [key: string]: boolean }>(
    {}
  );

  const [isUpdatePending, setIsUpdatePending] = useState<boolean>(false);

  const updateBrandAsset = useUpdateBrandAsset();

  const handleUpdateBrandAsset = useCallback(() => {
    if (isUpdatePending) {
      return;
    }
    const selectedTagsArray = Object.keys(selectedTags).filter(
      (key) => selectedTags[key]
    );
    updateBrandAsset(
      assets,
      { tags: selectedTagsArray },
      setIsUpdatePending
    ).then(() => {
      handleOnClose();
    });
  }, [isUpdatePending, updateBrandAsset]);

  const handleOnClose = () => {
    setSelectedTags({});
    setClosedFolders({});
    onClose();
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedTags((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const toggleFolder = (value: string) => {
    setClosedFolders((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const renderTags = (tags: any) => {
    return Object.keys(tags).map((key) => {
      const item = tags[key];
      return (
        <Box key={key} width="100%">
          {item.children ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <IconButton
                    icon={<FaFolder />}
                    size="xs"
                    variant="ghost"
                    aria-label="folder"
                    color="primary.veryDark"
                    mr="2"
                  />
                  <Text color="#193638" fontSize="14px">
                    {item.label}
                  </Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton
                    icon={
                      !closedFolders[key] ? (
                        <Image src={CaretUp} />
                      ) : (
                        <Image src={CaretDown} />
                      )
                    }
                    size="xs"
                    variant="ghost"
                    onClick={() => toggleFolder(key)}
                    aria-label="Toggle folder"
                  />
                </Box>
              </Box>
              <Collapse in={!closedFolders[key]}>
                <VStack align="start" pl={4} pt={4}>
                  {renderTags(item.children)}
                </VStack>
              </Collapse>
            </>
          ) : (
            <Checkbox
              isChecked={selectedTags[key] || false}
              onChange={() => handleCheckboxChange(key)}
            >
              <Text color="#193638" fontSize="14px">
                {item.label}
              </Text>
            </Checkbox>
          )}
        </Box>
      );
    });
  };

  return (
    <Modal
      isOpen
      onClose={handleOnClose}
      blockScrollOnMount={false}
      size="full"
    >
      <ModalOverlay />
      <ModalContent
        mt="100px"
        borderRadius="16px 16px 0 0"
        maxH="55%"
        maxWidth={800}
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="absolute" left="4">
              <IconButton
                icon={
                  <Icon as={MdClose} color={"gray.medium"} boxSize="24px" />
                }
                aria-label="Menu"
                onClick={handleOnClose}
                bg={"transparent"}
              />
            </Box>
            <Box>
              <Text color="gray.dark">Edit tags</Text>
            </Box>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Text fontSize="13px" color="gray.medium" fontWeight="bold">
            Select the relevant categories
          </Text>
          <VStack align="start" spacing={4} mt={3}>
            {renderTags(ASSET_TAGS)}
          </VStack>
        </ModalBody>
        <ModalFooter mb="100px">
          <Button
            variant="fill"
            colorScheme="primary"
            width="full"
            onClick={handleUpdateBrandAsset}
            isLoading={isUpdatePending}
            borderRadius={7}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTagsModal;
