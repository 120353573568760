import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Resource } from "src/lib/types";

type EditResourceModalProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  editResource: Resource | null;
  setEditResource: (
    resource: ((prev: Resource | null) => Resource | null) | Resource | null
  ) => void;
  updateResource: () => void;
};

export function EditResourceModal({
  isOpen,
  onOpenChange,
  editResource,
  setEditResource,
  updateResource,
}: EditResourceModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={() => onOpenChange(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Resource</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} py={4}>
            <FormControl>
              <FormLabel htmlFor="edit-url">Resource URL</FormLabel>
              <Input
                id="edit-url"
                placeholder="Resource URL"
                value={editResource?.url || ""}
                onChange={(e) =>
                  setEditResource((prev) =>
                    prev ? { ...prev, url: e.target.value } : null
                  )
                }
                aria-label="Edit resource URL"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="edit-title">Resource Title</FormLabel>
              <Input
                id="edit-title"
                placeholder="Resource Title"
                value={editResource?.title || ""}
                onChange={(e) =>
                  setEditResource((prev: any) =>
                    prev ? { ...prev, title: e.target.value } : null
                  )
                }
                aria-label="Edit resource title"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="edit-description">
                Resource Description
              </FormLabel>
              <Textarea
                id="edit-description"
                placeholder="Resource Description"
                value={editResource?.description || ""}
                onChange={(e) =>
                  setEditResource((prev) =>
                    prev ? { ...prev, description: e.target.value } : null
                  )
                }
                aria-label="Edit resource description"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={updateResource}
            colorScheme="blue"
            isDisabled={
              !editResource?.url ||
              !editResource?.title ||
              !editResource?.description
            }
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
