import {
  Firestore,
  CollectionReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  SnapshotOptions,
  collection,
} from "firebase/firestore";
import { AudienceAssetValue } from "./assets/audienceAsset";
import { ColorsAssetValue } from "./assets/colorsAsset";
import { FontAssetValue } from "./assets/fontAsset";
import { LogoAssetValue } from "./assets/logoAsset";
import { NameAssetValue } from "./assets/nameAsset";
import { SloganAssetValue } from "./assets/sloganAsset";
import { ValueAssetValue } from "./assets/valueAsset";
import { BrandStoryAssetValue } from "./assets/brandStoryAsset";
import { BrandAssetValue } from "./assets/brandAsset";

export enum AssetTypes {
  audience = "targetAudience",
  color = "color",
  font = "font",
  logo = "logo",
  brandAsset = "brandAsset",
  name = "name",
  slogan = "slogan",
  brandStory = "brandStories",
  value = "brandValue",
}

export enum BrandAssetTypes {
  productImages = "productImages",
  brandingAssets = "brandingAssets",
  logoVariants = "logoVariants",
  fonts = "fonts",
  brandingColors = "brandingColors",
  marketingData = "marketingData",
  salesData = "salesData",
  other = "other",
}

export type UserReviews = "POSITIVE" | "NEGATIVE" | "INITIAL";
export type AssetOrigins = "AI" | "USER" | "HYBRID";
export type AssetValueTypes =
  | AudienceAssetValue
  | ColorsAssetValue
  | FontAssetValue
  | LogoAssetValue
  | BrandAssetValue
  | NameAssetValue
  | SloganAssetValue
  | ValueAssetValue
  | BrandStoryAssetValue;

/**
 * All Asset Values For Convenient Reference:
 *
 *  value: {
 *    targetAudience?: string;
 *    color?: sting[];
 *    font?: {name: string, url: string, weight: string };
 *    logo?: [string, string];
 *    name?: string;
 *    slogan?: string;
 *    brandValue?: string;
 *  }
 */

export interface AssetDocument<V = AssetValueTypes> {
  id: string;
  businessId: string;
  assetType: AssetTypes;
  userReview: UserReviews;
  selected: boolean;
  origin: AssetOrigins;
  explanation: string;
  createdAt: Date;
  value: V;
  // Only implemented for text-based assets.
  renderLabel: () => string;
}

export abstract class AssetDocumentConverter<V = AssetValueTypes>
  implements FirestoreDataConverter<AssetDocument<V>>
{
  protected assetValueFromFirestore(assetValue: any): V {
    throw new Error("Not Implemented");
  }

  // This will be rebound to be called with the `fromFirestore` value.
  protected renderLabel(value: V): string {
    throw new Error("Label Not Implemented for this type");
  }

  toFirestore(asset: AssetDocument<V>) {
    return {
      assetData: {
        assetExplanation: asset.explanation,
        assetValue: asset.value,
      },
      businessId: asset.businessId,
      assetOrigin: asset.origin,
      assetType: asset.assetType,
      selectedForBrandKit: asset.selected,
      userReview: asset.userReview,
    };
  }

  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): AssetDocument<V> {
    const data = snapshot.data(options)!;
    const value = this.assetValueFromFirestore(data.assetData.assetValue);
    return {
      id: snapshot.id,
      businessId: data.businessId,
      assetType: data.assetType,
      explanation: data.assetData.assetExplanation,
      origin: data.assetOrigin,
      selected: data.selectedForBrandKit,
      userReview: data.userReview,
      createdAt: data.createdAt.toDate(),
      value: value,
      renderLabel: this.renderLabel.bind(this, value),
    };
  }
}

export function assetCollection(
  fs: Firestore,
  assetType: AssetTypes
): CollectionReference {
  const base = "asset";
  switch (assetType) {
    case AssetTypes.audience:
      return collection(fs, base + "TargetAudiences");
    case AssetTypes.color:
      return collection(fs, base + "Colors");
    case AssetTypes.font:
      return collection(fs, base + "Fonts");
    case AssetTypes.logo:
      return collection(fs, base + "Logos");
    case AssetTypes.brandAsset:
      return collection(fs, "brandUploads");
    case AssetTypes.name:
      return collection(fs, base + "Names");
    case AssetTypes.slogan:
      return collection(fs, base + "Slogans");
    case AssetTypes.value:
      return collection(fs, base + "BrandValues");
    case AssetTypes.brandStory:
      return collection(fs, base + "BrandStories");
  }
}
