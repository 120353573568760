import { AssetDocument, AssetDocumentConverter } from '../asset';
import { FileInfo } from '../common';

interface DynamicBrandDetails {
  name: string;
  fileInfo: FileInfo;
  tags: string[];
}

export interface BrandAssetValue {
  brand: DynamicBrandDetails;
}

export type BrandAssetDocument = AssetDocument<BrandAssetValue>;

class BrandAssetDocumentConverter extends AssetDocumentConverter<BrandAssetValue> {
  override assetValueFromFirestore(assetValue: any) : BrandAssetValue {

    const value: BrandAssetValue = {
      brand: {
        name: assetValue.brandAsset.name || '',
        fileInfo: {
          storageId: assetValue.brandAsset.fileInfo.storageId || '',
          fileUrl: assetValue.brandAsset.fileInfo.fileUrl || '',
        },
        tags: assetValue.brandAsset.tags
      },
    };

    return value;
  }
}

export const brandAssetConverter = new BrandAssetDocumentConverter();
