import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { BrandAssetDocument } from "src/db";
import { Delete, Download, Rename, Square, Tag } from "src/images";

const AssetDropdownActions = ({
  children,
  assets,
  handleRenameFile,
  handleRemoveFile,
  handleEditTags,
  handleSelectAssets
}: {
  children: ReactNode;
  assets: BrandAssetDocument | BrandAssetDocument[];
  handleRenameFile: () => void;
  handleRemoveFile: () => void;
  handleEditTags: () => void;
  handleSelectAssets?: (asset: BrandAssetDocument) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadFile = () => {
    const downloadAssets = Array.isArray(assets) ? assets : [assets];
    downloadAssets.forEach((asset) => {
      const link = document.createElement("a");
      link.href = asset.value.brand.fileInfo.fileUrl;
      link.download = asset.value.brand.name || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton as="button" onClick={onOpen}>
        {children}
      </MenuButton>

      <MenuList
        zIndex={999999}
        top="70px"
        boxShadow="lg"
        borderRadius="24px"
        bg="grayscale.offwhite"
        w="300px"
        shadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 29px 0px rgba(0, 0, 0, 0.10)"
      >
        {(Array.isArray(assets) ? [
          { icon: <Image src={Tag} />, label: "Edit tags", onClick: handleEditTags },
          {
            icon: <Image src={Delete} />,
            label: "Delete",
            color: "red.500",
            onClick: handleRemoveFile
          },
        ] : [
          {
            icon: <Image src={Download} />,
            label: "Download",
            onClick: downloadFile,
          },
          { icon: <Image src={Square} />, label: "Select", onClick: () => handleSelectAssets && handleSelectAssets(assets), disabled: !handleSelectAssets },
          { icon: <Image src={Tag} />, label: "Edit tags", onClick: handleEditTags },
          {
            icon: <Image src={Rename} />,
            label: "Rename",
            onClick: handleRenameFile,
          },
          {
            icon: <Image src={Delete} />,
            label: "Delete",
            color: "red.500",
            onClick: handleRemoveFile
          },
        ]).map((item, index) => {
          if(item.disabled) return null;
          return (
            <MenuItem
              key={index}
              color="#193638"
              icon={item.icon}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottomColor="gray.lightest"
              borderBottomWidth={1}
              borderStyle="solid"
              px="4"
              py="2"
              height="36px"
              cursor="pointer"
              onClick={item.onClick}
            >
              {item.label}
            </MenuItem>
          )
        })}
        {/* <Flex
          py="2"
          px="4"
          height="36px"
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
        >
          <Flex gap="12px">
            <Image src={Exclude} />
            <Text color="#193638">Exclude from AI Model</Text>
          </Flex>
          <Switch size="sm" id="email-alerts" />
        </Flex> */}
      </MenuList>
    </Menu>
  );
};

export default AssetDropdownActions;
