import { Auth } from 'firebase/auth';

import { logger, LogSource } from 'src/util/logger';

import { ApiMethods } from './ApiMethods';

import { ApiResponse, toApiResponse } from './messages/ApiResponse';
import { AddAssetRequest, AddAssetResponse } from './messages/AddAsset';
import { AddColorAssetRequest, AddColorAssetResponse } from './messages/AddColorAsset';
import { ClaimBusinessRequest, ClaimBusinessResponse } from './messages/ClaimBusiness';
import { CompleteBrandActionRequest, CompleteBrandActionResponse } from './messages/CompleteBrandAction';
import { CreateBrandActionSocialMediaPostRequest, CreateBrandActionSocialMediaPostResponse } from './messages/CreateBrandActionSocialMediaPost';
import { CreateBusinessRequest, CreateBusinessResponse } from './messages/CreateBusiness';
import { ScrapeBusinessUrlRequest, ScrapeBusinessUrlResponse } from './messages/ScrapeBusinessUrl';
import { CreateCheckoutSessionRequest, CreateCheckoutSessionResponse } from './messages/CreateCheckoutSession';
import { CreateProfileRequest, CreateProfileResponse } from './messages/CreateProfile';
import { LoadAgentThreadMessagesRequest, LoadAgentThreadMessagesResponse } from './messages/LoadAgentThreadMessages';
import { LockBrandKitRequest, LockBrandKitResponse } from './messages/LockBrandKit';
import { UnlockBrandKitRequest, UnlockBrandKitResponse } from './messages/UnlockBrandKit';
import { GenerateAssetsRequest, GenerateAssetsResponse } from './messages/GenerateAssets';
import { ReviewAssetRequest, ReviewAssetResponse } from './messages/ReviewAsset';
import { SelectAssetRequest, SelectAssetResponse } from './messages/SelectAsset';
import { SendAgentThreadMessageRequest, SendAgentThreadMessageResponse } from './messages/SendAgentThreadMessage';
import { UpdateAssetDataRequest, UpdateAssetDataResponse } from './messages/UpdateAssetData';
import { UpdateBusinessDescriptionRequest, UpdateBusinessDescriptionResponse } from './messages/UpdateBusinessDescription';
import { UpdateBrandActionSocialMediaPostRequest, UpdateBrandActionSocialMediaPostResponse } from './messages/UpdateBrandActionSocialMediaPost';
import { UpdateEditedLogoRequest, UpdateEditedLogoResponse } from './messages/UpdateEditedLogo';
import { UpdateProfileRequest, UpdateProfileResponse } from './messages/UpdateProfile';
import { UpdateTosAndPpRequest, UpdateTosAndPpResponse } from './messages/UpdateTosAndPp';

import { ApiCache } from './util/ApiCache';
import { ErrorSurfacer } from './util/errors';
import { UpdateReportRequest, UpdateReportResponse } from './messages/UpdateReportContent';
import { DeleteReportRequest, DeleteReportResponse } from './messages/DeleteReportContent';
import { UpdateBusinessDifferentiatorRequest, UpdateBusinessDifferentiatorResponse } from './messages/UpdateBusinessDifferentiator';
import { GenerateReportsRequest, GenerateReportsResponse } from './messages/GenerateReports';
import { DeclineMemberRequest, InviteMembersRequest, RemoveMemberRequest, RevokeUserInvitationRequest, UpdateMemberPermissionsRequest } from './messages/Members';
import { GetCDNARequest } from './messages/GetCDNA';

export type NotifyPending = (method: ApiMethods) => void;
export type NotifyComplete = (method: ApiMethods) => void;
export type BeforeComplete = () => void;

interface DoMethodOptions {
  // Mandatory error surfacer
  surfaceErrors: ErrorSurfacer;

  // Optional callback to run before marking the call as complete
  beforeComplete?: BeforeComplete;

  // Optional treat the rew body as form data. Default false
  useForm?: boolean;
}

export class Api {
  constructor(
    private baseUrl: string,
    private auth: Auth,
    private notifyPending: NotifyPending,
    private notifyComplete: NotifyComplete
  ) {}

  private cache = new ApiCache();

  private getOrRefreshAuthToken(): Promise<string> {
    if (!this.auth.currentUser) {
      logger.warn(LogSource.APP, 'API Called without a User');
      // TODO: Lockdown API after making Login Page
      // throw new Error('Cannot call API without a User');
      return Promise.resolve('none');
    }
    return this.auth.currentUser.getIdToken();
  }

  private async doMethod<
    RequestType extends Object,
    ResponseType extends ApiResponse<any>
  >(
    method: ApiMethods,
    body: RequestType,
    options: DoMethodOptions,
    verb = 'POST'
  ): Promise<ResponseType> {
    const { surfaceErrors, beforeComplete, useForm } = options;
    // NOTE: FormData is not accurately stringified,
    // so all requests to the same method will hit the same cache.
    if (this.cache.hasRequest(method, body)) {
      logger.info(LogSource.APP, 'Using existing request...', {silent: true});
      const response = this.cache.awaitExistingRequest(method, body);
      return response;
    } else {
      this.cache.addNewRequest(method, body);
      this.notifyPending(method);
      try {
        const url = this.baseUrl + method;
        let apiResponse;
        if (useForm) {
          const data = body as unknown as FormData;
          apiResponse = await this.doForm(url, data, verb);
        } else {
          apiResponse = await this.makeRequest<RequestType>(url, body, verb);
        }
        if (apiResponse.status === 'success') {
          this.cache.publishResponse(method, body, apiResponse);
          return apiResponse as ResponseType;
        } else {
          surfaceErrors(apiResponse);
          this.cache.publishError(method, body, apiResponse);
          return apiResponse as ResponseType;
        }
      } catch (otherResponse) {
        logger.severe(LogSource.API, 'Unknown Error:\n', otherResponse as Error);
        const unknownResponse: ApiResponse<null> = {
          status: 'error',
          data: null,
          message: 'Unknown Error',
        };
        this.cache.publishError(method, body, unknownResponse);
        return unknownResponse as ResponseType;
      } finally {
        !!beforeComplete && beforeComplete();
        this.notifyComplete(method);
      }
    }
  }

  private async makeRequest<RequestType extends Object>(
    url: string,
    body: RequestType,
    verb: string
  ): Promise<ApiResponse<unknown>> {
    try {
      const authToken = await this.getOrRefreshAuthToken();
      const fetchResponse = await fetch(url, {
        method: verb,
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-type': 'application/json',
        },
        mode: 'cors',
      });
      // Note: Can be 'success' or 'error', this just ensures the return value (if not thrown) will be ApiResponse;
      const fetchJson = await fetchResponse.json();
      return toApiResponse(fetchJson);
    } catch (e) {
      logger.error(LogSource.API, 'Failed to complete JSON Request', e as Error);
      return { status: 'error', data: null, message: 'Failed to complete JSON Request' };
    }
  }

  // TODO: Figureout how to incorporate this with cache.
  // Current limitation is strinigifying req body.
  private async doForm(
    url: string,
    data: FormData,
    verb = 'POST'
  ): Promise<ApiResponse<unknown>> {
    try {
      const authToken = await this.getOrRefreshAuthToken();
      const fetchResponse = await fetch(url, {
        method: verb,
        body: data,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // Note: Can be 'success' or 'error', this just ensures the return value (if not thrown) will be ApiResponse;
      const fetchJson = await fetchResponse.json();
      return toApiResponse(fetchJson);
    } catch (e) {
      logger.error(LogSource.API, 'Failed to complete FormData Request', e as Error);
      return { status: 'error', data: null, message: 'Failed to complete FormData Request' };
    }
  }

  async addAsset(
    request: AddAssetRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<AddAssetResponse> {
    logger.info(LogSource.APP, 'Adding asset...', {silent: true});
    return await this.doMethod<AddAssetRequest, AddAssetResponse>(
      ApiMethods.ADD_ASSET,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async addColorAsset(
    request: AddColorAssetRequest, surfaceErrors: ErrorSurfacer, beforeComplete?: BeforeComplete,
  ): Promise<AddColorAssetResponse> {
    logger.info(LogSource.APP, 'Adding color asset...', {silent: true});
    return await this.doMethod<AddColorAssetRequest, AddColorAssetResponse>(
      ApiMethods.ADD_COLOR_ASSET, request, {surfaceErrors, beforeComplete},
    );
  }

  async claimBusiness(
    request: ClaimBusinessRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<ClaimBusinessResponse> {
    logger.info(LogSource.APP, 'Claiming business...', {silent: true});
    return await this.doMethod<ClaimBusinessRequest, ClaimBusinessResponse>(
      ApiMethods.CLAIM_BUSINESS,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async completeBrandAction(
    request: CompleteBrandActionRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<CompleteBrandActionResponse> {
    logger.info(LogSource.APP, 'Complete brand action...', {silent: true});
    return await this.doMethod<CompleteBrandActionRequest, CompleteBrandActionResponse>(
      ApiMethods.COMPLETE_BRAND_ACTION,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async createBrandActionSocialMediaPost(
    request: CreateBrandActionSocialMediaPostRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<CreateBrandActionSocialMediaPostResponse> {
    logger.info(LogSource.APP, 'Creating brand action (social media post)...');
    return await this.doMethod<CreateBrandActionSocialMediaPostRequest, CreateBrandActionSocialMediaPostResponse>(
      ApiMethods.CREATE_BRAND_ACTION_SOCIAL_MEDIA_POST,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async createOrJoinBusiness(
    request: CreateBusinessRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<CreateBusinessResponse> {
    logger.info(LogSource.APP, 'Creating or joining business...', {silent: true});
    return await this.doMethod<CreateBusinessRequest, CreateBusinessResponse>(
      ApiMethods.CREATE_BUSINESS,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async scrapeBusinessUrl(
    request: ScrapeBusinessUrlRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<ScrapeBusinessUrlResponse> {
    logger.info(LogSource.APP, 'Gathering business info from URL...', {silent: true});
    return await this.doMethod<ScrapeBusinessUrlRequest, ScrapeBusinessUrlResponse>(
      ApiMethods.SCRAPE_BUSINESS_URL,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async createProfile(
    request: CreateProfileRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<CreateProfileResponse> {
    logger.info(LogSource.APP, 'Creating profile...', {silent: true});
    return await this.doMethod<CreateProfileRequest, CreateProfileResponse>(
      ApiMethods.CREATE_PROFILE,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async createCheckoutSession(
    request: CreateCheckoutSessionRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<CreateCheckoutSessionResponse> {
    logger.info(LogSource.APP, 'Creating payment checkout session...', {silent: true});
    return await this.doMethod<
      CreateCheckoutSessionRequest,
      CreateCheckoutSessionResponse
    >(ApiMethods.CREATE_CHECKOUT_SESSION, request, {
      surfaceErrors,
      beforeComplete,
    });
  }

  async loadAgentThreadMessages(
    request: LoadAgentThreadMessagesRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<LoadAgentThreadMessagesResponse> {
    logger.info(LogSource.APP, 'Loading Agent Thread Messages...', {silent: true});
    return await this.doMethod<
      LoadAgentThreadMessagesRequest,
      LoadAgentThreadMessagesResponse
    >(ApiMethods.LOAD_AGENT_THREAD_MESSAGES, request, {
      surfaceErrors,
      beforeComplete,
    });
  }

  async lockBrandKit(
    request: LockBrandKitRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<LockBrandKitResponse> {
    logger.info(LogSource.APP, 'Locking Brand Kit...', {silent: true});
    return await this.doMethod<LockBrandKitRequest, LockBrandKitResponse>(
      ApiMethods.LOCK_BRANDKIT,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async unlockBrandKit(
    request: UnlockBrandKitRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UnlockBrandKitResponse> {
    logger.info(LogSource.APP, 'Unlocking Brand Kit...', {silent: true});
    return await this.doMethod<UnlockBrandKitRequest, UnlockBrandKitResponse>(
      ApiMethods.UNLOCK_BRANDKIT,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async generateAssets(
    request: GenerateAssetsRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<GenerateAssetsResponse> {
    logger.info(LogSource.APP, `Generating ${request.assetType} assets...`, {silent: true});
    return await this.doMethod<GenerateAssetsRequest, GenerateAssetsResponse>(
      ApiMethods.GENERATE_ASSETS,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async generateReports(
    request: GenerateReportsRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<GenerateReportsResponse> {
    logger.info(LogSource.APP, `Generating report...`, {silent: true});
    return await this.doMethod<GenerateReportsRequest, GenerateReportsResponse>(
      ApiMethods.GENERATE_REPORTS,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async reviewAsset(
    request: ReviewAssetRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<ReviewAssetResponse> {
    logger.info(LogSource.APP, 'Reviewing asset...', {silent: true});
    return await this.doMethod<ReviewAssetRequest, ReviewAssetResponse>(
      ApiMethods.REVIEW_ASSET,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async selectAsset(
    request: SelectAssetRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<SelectAssetResponse> {
    logger.info(LogSource.APP, 'Selecting asset...', {silent: true});
    return await this.doMethod<SelectAssetRequest, SelectAssetResponse>(
      ApiMethods.SELECT_ASSET,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async sendAgentThreadMessage(
    request: SendAgentThreadMessageRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<SendAgentThreadMessageResponse> {
    logger.info(LogSource.APP, 'Sending agent thread message...', {silent: true});
    return await this.doMethod<
      SendAgentThreadMessageRequest,
      SendAgentThreadMessageResponse
    >(ApiMethods.SEND_AGENT_THREAD_MESAAGE, request, {
      surfaceErrors,
      beforeComplete,
    });
  }

  async updateAssetData(
    request: UpdateAssetDataRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateAssetDataResponse> {
    logger.info(LogSource.APP, 'Updating asset data...', {silent: true});
    return await this.doMethod<UpdateAssetDataRequest, UpdateAssetDataResponse>(
      ApiMethods.UPDATE_ASSET_DATA,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateBusinessDescription(
    request: UpdateBusinessDescriptionRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateBusinessDescriptionResponse> {
    logger.info(LogSource.APP, 'Updating business description...', {silent: true});
    return await this.doMethod<UpdateBusinessDescriptionRequest, UpdateBusinessDescriptionResponse>(
      ApiMethods.UPDATE_BUSINESS_DESCRIPTION,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateBusinessDifferentiator(
    request: UpdateBusinessDifferentiatorRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateBusinessDifferentiatorResponse> {
    logger.info(LogSource.APP, 'Updating business differentiator...', {silent: true});
    return await this.doMethod<UpdateBusinessDifferentiatorRequest, UpdateBusinessDifferentiatorResponse>(
      ApiMethods.UPDATE_BUSINESS_DIFFERENTIATOR,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateReport(
    request: UpdateReportRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateReportResponse> {
    logger.info(LogSource.APP, 'Updating report content...', {silent: true});
    return await this.doMethod<UpdateReportRequest, UpdateReportResponse>(
      ApiMethods.UPDATE_REPORT,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async deleteReport(
    request: DeleteReportRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<DeleteReportResponse> {
    logger.info(LogSource.APP, 'Deleting report...', {silent: true});
    return await this.doMethod<DeleteReportRequest, DeleteReportResponse>(
      ApiMethods.DELETE_REPORT,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateBrandActionSocialMediaPost(
    request: UpdateBrandActionSocialMediaPostRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateBrandActionSocialMediaPostResponse> {
    logger.info(LogSource.APP, 'Updating brand action (social media post)...', {silent: true});
    return await this.doMethod<UpdateBrandActionSocialMediaPostRequest, UpdateBrandActionSocialMediaPostResponse>(
      ApiMethods.UPDATE_BRAND_ACTION_SOCIAL_MEDIA_POST,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateEditedLogo(
    request: UpdateEditedLogoRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateEditedLogoResponse> {
    logger.info(LogSource.APP, 'Updating edited logo...', {silent: true});
    return await this.doMethod<
      UpdateEditedLogoRequest,
      UpdateEditedLogoResponse
    >(ApiMethods.UPDATE_EDITED_LOGO, request, {
      surfaceErrors,
      beforeComplete,
    });
  }

  async updateProfile(
    request: UpdateProfileRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateProfileResponse> {
    logger.info(LogSource.APP, 'Updating profile...', {silent: true});
    return await this.doMethod<UpdateProfileRequest, UpdateProfileResponse>(
      ApiMethods.UPDATE_PROFILE,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

  async updateTosAndPp(
    request: UpdateTosAndPpRequest,
    surfaceErrors: ErrorSurfacer,
    beforeComplete?: BeforeComplete
  ): Promise<UpdateTosAndPpResponse> {
    logger.info(LogSource.APP, 'Updating Terms of Service and Privacy Policy...', {silent: true});
    return await this.doMethod<UpdateTosAndPpRequest, UpdateTosAndPpResponse>(
      ApiMethods.UPDATE_TOS_AND_PP,
      request,
      { surfaceErrors, beforeComplete }
    );
  }

    // Members
    async inviteMembers(
      request: InviteMembersRequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Inviting members...', {silent: true});
      return await this.doMethod<InviteMembersRequest, ApiResponse<null>>(
        ApiMethods.MEMBERS,
        request,
        { surfaceErrors, beforeComplete }
      );
    }

    async declineMember(
      request: DeclineMemberRequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Declining member...', {silent: true});
      return await this.doMethod<DeclineMemberRequest, ApiResponse<null>>(
        ApiMethods.MEMBERS_DECLINE,
        request,
        { surfaceErrors, beforeComplete }
      );
    }

    async removeMember(
      request: RemoveMemberRequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Removing member...', {silent: true});
      return await this.doMethod<RemoveMemberRequest, ApiResponse<null>>(
        ApiMethods.MEMBERS,
        request,
        { surfaceErrors, beforeComplete },
        'DELETE'
      );
    }

    async revokeUserInvitation(
      request: RevokeUserInvitationRequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Revoking user invitation...', {silent: true});
      return await this.doMethod<RevokeUserInvitationRequest, ApiResponse<null>>(
        ApiMethods.USER_INVITATIONS,
        request,
        { surfaceErrors, beforeComplete },
        'DELETE'
      );
    }

    async updateMemberPermissions(
      request: UpdateMemberPermissionsRequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Updating member permissions...', {silent: true});
      return await this.doMethod<UpdateMemberPermissionsRequest, ApiResponse<null>>(
        ApiMethods.UPDATE_MEMBER_PERMISSIONS,
        request,
        { surfaceErrors, beforeComplete },
        'PUT'
      );
    }

    async getCDNA(
      request: GetCDNARequest,
      surfaceErrors: ErrorSurfacer,
      beforeComplete?: BeforeComplete
    ): Promise<ApiResponse<null>> {
      logger.info(LogSource.APP, 'Getting CDNA...', {silent: true});
      return await this.doMethod<GetCDNARequest, ApiResponse<null>>(
        ApiMethods.GET_CDNA,
        request,
        { surfaceErrors, beforeComplete },
        'POST'
      );
    }

  /**
   * END JSON METHODS
   *
   * BEGIN FORM DATA METHODS
   */

  async addLogoAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Adding logo asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.ADD_LOGO_ASSET,
      data,
      { surfaceErrors, useForm: true }
    );
  }

  async addBrandAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Adding brand asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.BRAND_ASSET,
      data,
      { surfaceErrors, useForm: true }
    );
  }

  async deleteBrandAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Removing brand asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.BRAND_ASSET,
      data,
      { surfaceErrors, useForm: true },
      'DELETE'
    );
  }

  async updateBrandAsset(
    assetId: string,
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Updating brand asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      `${ApiMethods.BRAND_ASSET}/${assetId}` as ApiMethods,
      data,
      { surfaceErrors, useForm: true },
      'PUT'
    );
  }

  async addFontAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Adding font asset...', { silent: true });
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.ADD_FONT_ASSET,
      data,
      { surfaceErrors, useForm: true }
    );
  }

  async addSimilarLogoAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Adding similar logo asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.ADD_SIMILAR_LOGO_ASSET,
      data,
      { surfaceErrors, useForm: true }
    );
  }

  async addSocialMediaPostAsset(
    data: FormData,
    surfaceErrors: ErrorSurfacer
  ): Promise<ApiResponse<unknown>> {
    logger.info(LogSource.APP, 'Adding Social Media Post asset...', {silent: true});
    return await this.doMethod<FormData, ApiResponse<null>>(
      ApiMethods.ADD_SOCIAL_MEDIA_POST_ASSET,
      data,
      { surfaceErrors, useForm: true }
    );
  }
}
