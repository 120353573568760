import { Path } from "src/nav";

import { HeaderFooterPage } from "../wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "../wrappers/RequireBusiness";

import { BrandAssetTypes } from "src/db/model/asset";
import { CARD_CONTENT_BY_TAG } from "src/components/assets/brandAssetsCard/constants";
import AssetFolderContent from "./AssetFolderContent";

export function AssetChildFolderPage({ tag }: { tag: BrandAssetTypes }) {
  return (
    <HeaderFooterPage
      pageTitle={CARD_CONTENT_BY_TAG[tag]?.title}
      backTo={Path.brandAssets}
    >
      <RequireBusinessWithSignup>
        <AssetFolderContent tag={tag} />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}
