import React, { PropsWithChildren, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Avatar,
  BoxProps,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
  Box,
  Menu,
  MenuButton,
} from "@chakra-ui/react";

import { UserContext } from "src/auth";
import { useBusiness } from "src/db";
import { talawa, BellActive, User, Bell, ArrowLeft } from "src/images";
import { Path, useNavigateWithParams } from "src/nav";
import { useBusinesses, useNotifications } from "src/db/hooks";
import NotificationsModal from "../NotificationsModal";
import BrandSwitcherDropdown from "./BrandSwitcherDropdown";

interface NavLinkProps extends PropsWithChildren {
  to: Path;
}

interface HeaderMenuProps extends BoxProps {
  pageTitle?: string;
  backTo?: Path;
  hideLogo?: boolean;
}
export function HeaderMenu({
  hideLogo = false,
  pageTitle,
  backTo,
  ...boxProps
}: HeaderMenuProps) {
  const { pathname, search } = useLocation();
  const user = useContext(UserContext);
  const business = useBusiness();
  const isSignedIn = !!user && !user.isAnonymous;
  const isLoginPage = pathname === Path.login;
  const isSignupPage = pathname === Path.signup;
  const [menuOpen, setMenuOpen] = useState(false);

  const [notificationsModal, setNotificationsModal] = useState<boolean>(false);

  const notifications = useNotifications();

  const navigate = useNavigateWithParams();

  const businesses = useBusinesses();

  const selectedBusinessDetails = businesses?.find(
    (b) => b.businessId === business?.id
  );

  const noBrandNameBusinesses = businesses?.filter(
    (businessData) => !businessData?.assetName?.assetData?.assetValue?.name
  );

  function NavLink({ to, children }: NavLinkProps) {
    if (to === pathname) {
      return (
        <Link to={`${to}${search}`} aria-current={true}>
          {children}
        </Link>
      );
    } else {
      return <Link to={`${to}${search}`}>{children}</Link>;
    }
  }

  const openNotifications = () => {
    setNotificationsModal(true);
  };

  const unreadNotifications =
    Boolean(notifications?.length) && notifications?.some((n) => !n.read);

  const selectedBusinessName =
    selectedBusinessDetails?.assetName?.assetData?.assetValue.name;

  const selectedBusinessLogo =
    selectedBusinessDetails?.assetLogo?.assetData?.assetValue.logo.imageInfo
      .imageUrl;

  const handleOpen = () => setMenuOpen(true);
  const handleClose = () => setMenuOpen(false);

  const brandSwitcher = () => (
    <>
      {/* Overlay */}
      {menuOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)"
          zIndex={1}
          onClick={handleClose}
        />
      )}
      <Menu onOpen={handleOpen} onClose={handleClose}>
        <MenuButton disabled={!businesses}>
          <Box w="30px">
            <IconButton
              icon={
                <Avatar
                  name={selectedBusinessName}
                  src={selectedBusinessLogo}
                  boxSize="28px"
                  size="sm"
                />
              }
              aria-label="Account"
              bg={"transparent"}
            />
          </Box>
        </MenuButton>
        <BrandSwitcherDropdown businesses={businesses} noBrandNameBusinesses={noBrandNameBusinesses} />
      </Menu>
    </>
  );

  if (isSignedIn && !!backTo) {
    return (
      <Flex
        as="nav"
        direction="row"
        align="center"
        minH={62}
        boxShadow={isSignedIn ? "0 3px 5px rgba(0, 0, 0, 0.1)" : undefined}
        position="relative"
        {...boxProps}
      >
        <Box position="absolute" left="4">
          <IconButton
            icon={<Image src={ArrowLeft} alt="back" height="28px" />}
            aria-label="Menu"
            onClick={() => navigate({ to: backTo })}
            bg={"transparent"}
          />
        </Box>
        <Box m="auto">
          <Text color="primary.veryDark" fontSize={18} fontWeight="bold">
            {pageTitle}
          </Text>
        </Box>
        {brandSwitcher()}
      </Flex>
    );
  }

  return (
    <>
      <Flex
        as="nav"
        direction="row"
        align="center"
        boxShadow={isSignedIn ? "0 3px 5px rgba(0, 0, 0, 0.1)" : undefined}
        {...boxProps}
      >
        {!hideLogo ? (
          <Link to={Path.newLanding}>
            <Image
              src={talawa}
              alt="Talawa"
              mixBlendMode="multiply"
              height="20px"
            />
          </Link>
        ) : null}
        <Spacer />
        <HStack
          flex="0 0 fit-content"
          spacing={4}
          divider={
            <Divider
              orientation="vertical"
              height={4}
              borderColor="grayscale.medium"
            />
          }
        >
          {!isSignedIn && (
            <HStack
              flex="0 0 fit-content"
              divider={
                <Text as="span" color="text.placeholder" mx={2}>
                  or
                </Text>
              }
            >
              {!isLoginPage && (
                <NavLink to={Path.login}>
                  <Text as="span" decoration="underline">
                    Login
                  </Text>
                </NavLink>
              )}
              {!isSignupPage && (
                <NavLink to={Path.signup}>
                  <Text as="span" decoration="underline">
                    Sign Up
                  </Text>
                </NavLink>
              )}
            </HStack>
          )}
          {isSignedIn && (
            <Box gap="12px" display="flex">
              <IconButton
                icon={
                  <Image
                    src={unreadNotifications ? BellActive : Bell}
                    alt="notifications"
                    height="28px"
                  />
                }
                aria-label="Notifications"
                onClick={openNotifications}
                bg={"transparent"}
              />
              <NavLink to={Path.account}>
                <IconButton
                  icon={<Image src={User} alt="account" height="28px" />}
                  aria-label="Account"
                  bg={"transparent"}
                />
              </NavLink>
              {brandSwitcher()}
            </Box>
          )}
        </HStack>
      </Flex>
      {notificationsModal && (
        <NotificationsModal
          isOpen={notificationsModal}
          handleClose={() => setNotificationsModal(false)}
          notifications={notifications}
        />
      )}
    </>
  );
}
