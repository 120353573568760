import { Path } from "src/nav";

import { HeaderFooterPage } from "../../wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "../../wrappers/RequireBusiness";

import { BrandAssetTypes } from "src/db/model/asset";
import AssetFolderContent from "../AssetFolderContent";
import { CARD_CONTENT_BY_TAG } from "src/components/assets/brandAssetsCard/constants";

export function BrandingAssetsChildFolderPage({
  tag,
}: {
  tag: BrandAssetTypes;
}) {
  return (
    <HeaderFooterPage
      pageTitle={CARD_CONTENT_BY_TAG[tag]?.title}
      backTo={Path.brandingAssets}
    >
      <RequireBusinessWithSignup>
        <AssetFolderContent tag={tag} parent={BrandAssetTypes.brandingAssets} />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}
