import { Box, Flex, Divider, Progress, Center } from "@chakra-ui/react";

import { AssetTypes, useAssetContext } from "src/db";
import { Path } from "src/nav";

import { AddBrandAssetButton } from "src/components/assets/addAsset/AddBrandAsset";
import BrandAssetCard from "src/components/assets/brandAssetsCard/BrandAssetCard";
import { HeaderFooterPage } from "../wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "../wrappers/RequireBusiness";

const SIDE_PADDING = 4;

export function AssetsPage() {
  return (
    <HeaderFooterPage pageTitle="Brand Assets" backTo={Path.brand}>
      <RequireBusinessWithSignup>
        <AssetsPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function AssetsPageContent() {
  const {
    collection: brandAssets,
    isLoading: logoAssetsAreLoading,
    error: collectionError,
  } = useAssetContext(AssetTypes.brandAsset);

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  }

  if (logoAssetsAreLoading) {
    return (
      <>
        <Box py={8} px={SIDE_PADDING}>
          <Progress size="lg" isIndeterminate={true} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Center my="12px">
        <AddBrandAssetButton accept=".jpg, .jpeg, .png, .svg, .ai, .eps" />
      </Center>
      <Flex align="center" direction="column" justify="start" mb={4}>
        <Divider borderColor="secondary.light" mb={8} />
        {Object.keys(brandAssets).map((tag: any) => {
          const data: any = brandAssets[tag];
          return <BrandAssetCard key={tag} tag={tag} data={data} />;
        })}
      </Flex>
    </>
  );
}
