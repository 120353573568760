import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "./wrappers/RequireBusiness";
import { Carousel } from "src/components";
import FilePreview from "src/components/assets/FilePreview";
import { SyntheticEvent, useCallback } from "react";
import { useNavigateWithParams } from "src/nav/hooks";
import { Path } from "src/nav";
import {
  useAssetCollection,
  useAssetContext,
  useIsBrandKitLocked,
} from "src/db/hooks";
import { AssetTypes, BrandAssetDocument, LogoAssetDocument } from "src/db";
import { LogoPreview } from "src/components/assets/LogoPreview";

export function BrandPage() {
  return (
    <HeaderFooterPage pageTitle="Brand">
      <RequireBusinessWithSignup>
        <BrandPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function BrandPageContent() {
  const navigate = useNavigateWithParams();
  const isBrandKitLocked = useIsBrandKitLocked();
  const logos = useAssetCollection<LogoAssetDocument>(AssetTypes.logo);

  const selectedLogos = logos.filter((logo: LogoAssetDocument) => {
    return logo.selected;
  });
  const suggestedLogos = logos.filter((logo: LogoAssetDocument) => {
    return !logo.selected;
  });
  const previewLogos = !!selectedLogos.length ? selectedLogos : suggestedLogos;

  const { collection: brandAssets, isLoading: brandAssetsAreLoading } =
    useAssetContext<BrandAssetDocument>(AssetTypes.brandAsset);

  const goToBrandAssets = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandAssets });
    },
    [navigate]
  );

  const goToBrandkit = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandkit });
    },
    [navigate]
  );

  return (
    <>
      <Card
        mb={12}
        p={6}
        onClick={goToBrandkit}
        order={isBrandKitLocked ? 6 : undefined}
      >
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center">
            Brand Kit
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              All the key elements of your brand in one place
            </Text>
            {previewLogos.length === 0 && (
              <Progress isIndeterminate={true} width="50%" my={4} mx="auto" />
            )}
            {!isBrandKitLocked && (
              <Carousel itemWidth={200} itemSpacing={16} mb={2}>
                {previewLogos.map((logo, idx) => {
                  return <LogoPreview key="idx" logo={logo} />;
                })}
              </Carousel>
            )}
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={goToBrandkit}
          >
            Review
          </Button>
        </CardFooter>
      </Card>
      <Card mb={12} p={6}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Assets
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              Your brand assets at your fingertips
            </Text>
            {(brandAssets.length === 0 || brandAssetsAreLoading) && (
              <Progress isIndeterminate={true} width="50%" my={4} mx="auto" />
            )}
            {/* <Carousel itemWidth={100} itemSpacing={16}>
              {brandAssets.map((asset) => {
                return <FilePreview key={asset.id} file={asset.value.brand} />;
              })}
            </Carousel> */}
          </VStack>
        </CardBody>
        <VStack align="center">
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={goToBrandAssets}
          >
            Review
          </Button>
        </VStack>
      </Card>
    </>
  );
}
