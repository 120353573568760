import React, { PropsWithChildren, useCallback, useContext } from "react";
import { where, query } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import {
  MAX_SELECTED_AUDIENCES,
  MAX_SELECTED_COLORS,
  MAX_SELECTED_FONTS,
  MAX_SELECTED_LOGOS,
  MAX_SELECTED_NAMES,
  MAX_SELECTED_SLOGANS,
  MAX_SELECTED_BRAND_STORIES,
  MAX_SELECTED_VALUES,
} from 'src/api/constants';
import {
  // For Assets (generic)
  AssetDocument,
  AssetTypes,
  assetCollection,  
  // For Specific Assets
  AudienceAssetDocument,
  audienceAssetConverter,
  ColorsAssetDocument,
  colorsAssetConverter,
  FontAssetDocument,
  fontAssetConverter,
  LogoAssetDocument,
  logoAssetConverter,
  NameAssetDocument,
  nameAssetConverter,
  SloganAssetDocument,
  sloganAssetConverter,
  ValueAssetDocument,
  valueAssetConverter,
  BrandStoryAssetDocument,
  brandStoryAssetConverter,
  BrandAssetDocument,
  brandAssetConverter
} from 'src/db'; 
import { logger, LogSource } from 'src/util/logger';

import {
  FirestoreContext, AssetsContext, AssetsContextProperties, missingAssetsContext,
} from './contexts';
import { useBusinessId } from './hooks';
import { BrandAssetTypes } from "./model/asset";

export function AssetsProvider({children}: PropsWithChildren) {
  const businessId = useBusinessId();
  return (
    !businessId ?
    <AssetsContext.Provider value={missingAssetsContext}>
      {children}
    </AssetsContext.Provider> :
    <AssetsProviderInner businessId={businessId}>
      {children}
    </AssetsProviderInner>
  );
}

const getDataFromCollectionByTag = (tag: BrandAssetTypes, collection?: BrandAssetDocument[]) => collection?.filter(({ value }) => value.brand.tags?.includes(tag)) || []

interface AssetsProviderInnerProps {
  businessId: string;
}
export function AssetsProviderInner({
  businessId, children
}: PropsWithChildren<AssetsProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const businessIdFilter = where('businessId', '==', businessId);

  const selectedAssets = useCallback((assets?: AssetDocument<any>[]) => {
    if (!assets) return [];
    return assets.filter((asset) => { return asset.selected; });
  }, []);

  const [
    audienceCollection, audienceIsLoading, audienceError,
  ] = useCollectionData<AudienceAssetDocument>(
    query<AudienceAssetDocument>(
      assetCollection(
        fs, AssetTypes.audience
      ).withConverter<AudienceAssetDocument>(
        audienceAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    colorsCollection, colorsIsLoading, colorsError
  ] = useCollectionData<ColorsAssetDocument>(
    query<ColorsAssetDocument>(
      assetCollection(
        fs, AssetTypes.color
      ).withConverter<ColorsAssetDocument>(
        colorsAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    fontCollection, fontIsLoading, fontError
  ] = useCollectionData<FontAssetDocument>(
    query<FontAssetDocument>(
      assetCollection(
        fs, AssetTypes.font
      ).withConverter<FontAssetDocument>(
        fontAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    logoCollection, logoIsLoading, logoError
  ] = useCollectionData<LogoAssetDocument>(
    query<LogoAssetDocument>(
      assetCollection(
        fs, AssetTypes.logo
      ).withConverter<LogoAssetDocument>(
        logoAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    brandCollection, brandIsLoading, brandError
  ] = useCollectionData<BrandAssetDocument>(
    query<BrandAssetDocument>(
      assetCollection(
        fs, AssetTypes.brandAsset
      ).withConverter<BrandAssetDocument>(
        brandAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    nameCollection, nameIsLoading, nameError
  ] = useCollectionData<NameAssetDocument>(
    query<NameAssetDocument>(
      assetCollection(
        fs, AssetTypes.name
      ).withConverter<NameAssetDocument>(
        nameAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    sloganCollection, sloganIsLoading, sloganError
  ] = useCollectionData<SloganAssetDocument>(
    query<SloganAssetDocument>(
      assetCollection(
        fs, AssetTypes.slogan
      ).withConverter<SloganAssetDocument>(
        sloganAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    brandStoryCollection, brandStoryIsLoading, brandStoryError
  ] = useCollectionData<BrandStoryAssetDocument>(
    query<BrandStoryAssetDocument>(
      assetCollection(
        fs, AssetTypes.brandStory
      ).withConverter<BrandStoryAssetDocument>(
        brandStoryAssetConverter
      ),
      businessIdFilter,
    )
  );

  const [
    valueCollection, valueIsLoading, valueError
  ] = useCollectionData<ValueAssetDocument>(
    query<ValueAssetDocument>(
      assetCollection(
        fs, AssetTypes.value
      ).withConverter<ValueAssetDocument>(
        valueAssetConverter
      ),
      businessIdFilter,
    )
  );

  const providedValue: AssetsContextProperties = {
    [AssetTypes.audience]: {
      collection: audienceCollection || [],
      isLoading: audienceIsLoading,
      error: audienceError,
      isComplete:
          selectedAssets(audienceCollection).length === MAX_SELECTED_AUDIENCES,
    },
    [AssetTypes.color]: {
      collection: colorsCollection || [],
      isLoading: colorsIsLoading,
      error: colorsError,
      isComplete:
          selectedAssets(colorsCollection).length === MAX_SELECTED_COLORS,
    },
    [AssetTypes.font]: {
      collection: fontCollection || [],
      isLoading: fontIsLoading,
      error: fontError,
      isComplete:
          selectedAssets(fontCollection).length === MAX_SELECTED_FONTS,
    },
    [AssetTypes.logo]: {
      collection: logoCollection || [],
      isLoading: logoIsLoading,
      error: logoError,
      isComplete:
          selectedAssets(logoCollection).length === MAX_SELECTED_LOGOS,
    },
    [AssetTypes.brandAsset]: {
      collection: {
        [BrandAssetTypes.brandingAssets]: {
          children: {
            [BrandAssetTypes.brandingColors]: getDataFromCollectionByTag(BrandAssetTypes.brandingColors, brandCollection),
            [BrandAssetTypes.fonts]: getDataFromCollectionByTag(BrandAssetTypes.fonts, brandCollection),
            [BrandAssetTypes.logoVariants]: getDataFromCollectionByTag(BrandAssetTypes.logoVariants, brandCollection)
          }
        },
        [BrandAssetTypes.marketingData]: getDataFromCollectionByTag(BrandAssetTypes.marketingData, brandCollection),
        [BrandAssetTypes.other]: getDataFromCollectionByTag(BrandAssetTypes.other, brandCollection),
        [BrandAssetTypes.productImages]: getDataFromCollectionByTag(BrandAssetTypes.productImages, brandCollection),
        [BrandAssetTypes.salesData]: getDataFromCollectionByTag(BrandAssetTypes.salesData, brandCollection),
      },
      isLoading: brandIsLoading,
      error: brandError,
    },
    [AssetTypes.name]: {
      collection: nameCollection || [],
      isLoading: nameIsLoading,
      error: nameError,
      isComplete:
          selectedAssets(nameCollection).length === MAX_SELECTED_NAMES,
    },
    [AssetTypes.slogan]: {
      collection: sloganCollection || [],
      isLoading: sloganIsLoading,
      error: sloganError,
      isComplete:
          selectedAssets(sloganCollection).length === MAX_SELECTED_SLOGANS,
    },
    [AssetTypes.brandStory]: {
      collection: brandStoryCollection || [],
      isLoading: brandStoryIsLoading,
      error: brandStoryError,
      isComplete:
          selectedAssets(brandStoryCollection).length === MAX_SELECTED_BRAND_STORIES,
    },
    [AssetTypes.value]: {
      collection: valueCollection || [],
      isLoading: valueIsLoading,
      error: valueError,
      isComplete:
          selectedAssets(valueCollection).length === MAX_SELECTED_VALUES,
    },
  };

  if (audienceError) {logger.error(LogSource.FIRESTORE, 'audienceError:', audienceError);}
  if (colorsError) {logger.error(LogSource.FIRESTORE, 'colorsError:', colorsError);}
  if (fontError) {logger.error(LogSource.FIRESTORE, 'fontError:', fontError);}
  if (logoError) {logger.error(LogSource.FIRESTORE, 'logoError:', logoError);}
  if (brandError) {logger.error(LogSource.FIRESTORE, 'brandError:', brandError);}
  if (nameError) {logger.error(LogSource.FIRESTORE, 'nameError:', nameError);}
  if (sloganError) {logger.error(LogSource.FIRESTORE, 'sloganError:', sloganError);}
  if (valueError) {logger.error(LogSource.FIRESTORE, 'valueError:', valueError);}

  return (
    <AssetsContext.Provider value={providedValue}>
      {children}
    </AssetsContext.Provider>
  );
}
