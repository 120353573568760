import { Button, Card, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { IoMdTrash } from "react-icons/io";
import { Resource } from "src/lib/types";
import { truncateUrl } from "src/lib/utils";

type ResourcesProps = {
  resources: Resource[];
  customWidth?: number;
  handleCardClick?: (resource: Resource) => void;
  removeResource?: (url: string) => void;
};

export function Resources({
  resources,
  handleCardClick,
  removeResource,
  customWidth,
}: ResourcesProps) {
  return (
    <Flex
      data-test-id="resources"
      overflowX="auto"
      flexDirection="column"
      gap="8px"
    >
      {resources.map((resource, idx) => (
        <Card
          data-test-id={`resource`}
          key={idx}
          rounded="xl"
          cursor="pointer"
          w={customWidth + "px" || "320px"}
          onClick={() => handleCardClick?.(resource)}
          p="2"
          bg="gray.lightest"
        >
          <Flex justifyContent="space-between">
            <Flex direction="column">
              <Flex
                maxW={customWidth ? customWidth - 30 + "px" : "230px"}
              >
                <Text isTruncated>
                  {resource.title}
                </Text>
              </Flex>
              <Flex
                fontSize="base"
                mt="2"
                maxW={customWidth ? customWidth - 30 + "px" : "250px"}
                overflowWrap="break-word"
              >
                {resource.description?.length > 250
                  ? resource.description.slice(0, 250) + "..."
                  : resource.description}
              </Flex>
              <Flex
                as="a"
                href={resource.url}
                target="_blank"
                rel="noopener noreferrer"
                fontSize="sm"
                color="primary"
                mt="3"
                title={resource.url}
                w={customWidth ? customWidth - 30 + "px" : "250px"}
                alignItems="center"
                gap="4px"
              >
                {resource.description && (
                  <>
                    <Image
                      src={`https://www.google.com/s2/favicons?domain=${resource.url}`}
                      alt="favicon"
                      w="16px"
                      height="16px"
                    />
                    <Text isTruncated>
                      {truncateUrl(resource.url)}
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
            {removeResource && (
              <Flex position="absolute" top="4" right="4">
                <Button
                  data-test-id="remove-resource"
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeResource?.(resource.url);
                  }}
                  aria-label={`Remove ${resource.url}`}
                >
                  <Icon as={IoMdTrash} />
                </Button>
              </Flex>
            )}
          </Flex>
        </Card>
      ))}
    </Flex>
  );
}
